import React from 'react';
import { List, Card, Tooltip } from 'antd';
import { CheckCircleFilled, PlusCircleOutlined, MinusCircleOutlined, InfoCircleOutlined } from '@ant-design/icons';

const PresetListMobile = ({
                              presets,
                              loading,
                              onToggleMessage,
                              onToggleUsage,
                              deckId,
                              deckType,
                          }) => {
    return (
        <List
            grid={{ gutter: 8, column: 1 }}
            loading={loading}
            dataSource={presets}
            locale={{ emptyText: 'No available presets' }}
            renderItem={preset => (
                <List.Item>
                    <Card
                        title={<span style={{ color: preset.use ? '#027b83' : '#000' }}>{preset.name}</span>} // Зеленый цвет при use = true
                        style={{
                            cursor: 'pointer',
                        }}
                        actions={[
                            <Tooltip key="toggle">
                                {preset.use ? (
                                    <MinusCircleOutlined
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            onToggleUsage(deckId, deckType, preset.id, preset.use);
                                        }}
                                        style={{ fontSize: '16px', color: '#8B0000' }}
                                    />
                                ) : (
                                    <PlusCircleOutlined
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            onToggleUsage(deckId, deckType, preset.id, preset.use);
                                        }}
                                        style={{ fontSize: '16px', color: '#02796b' }}
                                    />
                                )}
                            </Tooltip>,
                            <Tooltip title="Info" key="info">
                                <InfoCircleOutlined
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        onToggleMessage("Info", preset.name);
                                    }}
                                    style={{ fontSize: '16px', color: '#808080' }}
                                />
                            </Tooltip>,
                        ]}
                    >
                        <div>
                            <p>{preset.description}</p>
                            <p><strong>Items:</strong> {preset.itemCount}</p>
                            {preset.languageLocale && (
                                <p><strong>Language:</strong> {preset.languageLocale}</p>
                            )}
                        </div>
                    </Card>
                </List.Item>
            )}
        />
    );
};

export default PresetListMobile;
