// QuizInfoCard.jsx
import React, { useState } from 'react';
import './QuizInfoCard.css';
import { Card, Typography, List, Grid, Divider, Button, Modal, message } from 'antd';
import { EditOutlined } from "@ant-design/icons";
import EditQuizForm from "../EditQuizForm/EditQuizForm";

const { Text } = Typography;
const { useBreakpoint } = Grid;

const QuizInfoCard = ({ quiz, onUpdateQuiz }) => { // Добавляем пропс onUpdateQuiz для обновления
    const screens = useBreakpoint();
    const isMobile = !screens.md; // Определение мобильного устройства

    const [showCorrectAnswers, setShowCorrectAnswers] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);

    const handleCardClick = () => {
        setShowCorrectAnswers((prev) => !prev);
    };

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const handleSave = (updatedQuiz) => {
        console.log('Сохраненные данные викторины:', updatedQuiz);
        // Вызовите функцию обновления родительского компонента или Redux
        if (onUpdateQuiz) {
            onUpdateQuiz(updatedQuiz);
        } else {
            message.success('Quiz saved successfully!');
            // Здесь можно добавить дополнительную логику, например, отправку данных на сервер
        }
        setIsModalVisible(false);
    };

    const handleDelete = () => {
        // Реализуйте логику удаления викторины
        message.success('Quiz deleted successfully!');
    };

    const handleClearStatistics = () => {
        // Реализуйте логику очистки статистики
        message.success('Quiz statistics cleared successfully!');
    };

    return (
        <>
            <Card
                key={quiz.id}
                className="quizInfoCard"
                onClick={handleCardClick}
                bordered={false}
                styles={{ body: { padding: 0 } }}
                style={quiz.answersNotDefinitive ? { borderLeft: '1px solid #800000',borderRight: '1px solid #800000'  } : {}}
            >
                <div style={{ padding: '16px 16px 0 16px' }}>
                    {/* Вопрос */}
                    <Text
                        strong={!isMobile}
                        style={{
                            whiteSpace: 'pre-line', // Сохранение переносов строк
                            fontSize: isMobile ? '110%' : '20px', // Регулировка размера шрифта
                            display: 'block',
                            marginBottom: '12px',
                        }}
                    >
                        {quiz.question}
                    </Text>

                    {/* Ответы */}
                    <List
                        dataSource={quiz.answers}
                        renderItem={(answer, index) => (
                            <List.Item
                                key={index}
                                style={{
                                    border: showCorrectAnswers && answer.isCorrect
                                        ? '1px solid green'
                                        : '1px solid transparent',
                                    padding: '8px 16px',
                                    marginBottom: '8px',
                                    transition: 'border 0.3s',
                                }}
                            >
                                <Text
                                    style={{
                                        whiteSpace: 'pre-line',
                                        fontSize: isMobile ? '110%' : '16px',
                                        color: 'rgb(102,102,102)'
                                    }}>
                                    {answer.text}
                                </Text>
                            </List.Item>
                        )}
                    />

                    {/* Горизонтальный разделитель */}
                    <Divider style={{ margin: '2px 0' }} />

                    {/* Контейнер с тремя равными блоками */}
                    <div style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        paddingTop: '6px',
                        paddingBottom: '6px',
                        fontSize: '16px',
                    }}>
                        {/* Первый блок: Correct */}
                        <div style={{ flex: 1, textAlign: 'center', padding: '0 8px' }}>
                            <Text type="secondary">Correct: {quiz.correctAnswerCount}</Text>
                        </div>

                        {/* Вертикальный разделитель */}
                        <div style={{
                            width: '1px',
                            height: '20px',
                            backgroundColor: '#e8e8e8',
                        }} />

                        {/* Второй блок: Incorrect */}
                        <div style={{ flex: 1, textAlign: 'center', padding: '0 8px' }}>
                            <Text type="secondary">Incorrect: {quiz.incorrectAnswerCount}</Text>
                        </div>

                        {/* Вертикальный разделитель */}
                        <div style={{
                            width: '1px',
                            height: '20px',
                            backgroundColor: '#e8e8e8',
                        }} />

                        {/* Третий блок: Кнопка Edit */}
                        <div style={{ flex: 1, textAlign: 'center', padding: '0 8px' }}>
                            <Button
                                type="link"
                                icon={<EditOutlined />}
                                onClick={(e) => {
                                    e.stopPropagation(); // Предотвращаем всплытие события
                                    showModal();
                                }}
                            >
                                Edit
                            </Button>
                        </div>
                    </div>
                </div>
            </Card>

            {/* Модальное окно для редактирования викторины */}
            <Modal
                // title="Edit Quiz"
                title={`Edit Quiz: ${quiz.id}`}
                visible={isModalVisible}
                onCancel={handleCancel}
                footer={null} // Мы будем использовать кнопки внутри формы
                width={isMobile ? '90%' : '60%'}
                style={{ top: 16 }}
            >
                <EditQuizForm
                    quiz={quiz}
                    deckId={quiz.deckId}
                    onSave={handleSave}
                    onCancel={handleCancel}
                    onDelete={handleDelete}
                    onClearStatistics={handleClearStatistics}
                />
            </Modal>
        </>
    );
};

export default QuizInfoCard;
