// practiceReducer.js
const initialState = {
    practiceId: null,
    practiceType: null,
};

const practiceReducer = (state = initialState, action) => {
    switch(action.type){
        case 'SET_PRACTICE':
            return {
                ...state,
                practiceId: action.payload.practiceId,
                practiceType: action.payload.practiceType
            };
        case 'RESET_PRACTICE':
            return initialState;
        default:
            return state;
    }
};

export default practiceReducer;
