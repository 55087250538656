import customFetch from "../utils/fetchUtils";

const quizClient = {
    getAllQuizzes: async () => {
        return await customFetch(`/api/quizzes`, {
            method: 'GET',
        });
    },

    getQuizzesByDeckId: async (deckId, page = 0, size = 10) => {
        return await customFetch(`/api/quiz-info/deck/${deckId}?page=${page}&size=${size}`, {
            method: 'GET',
        });
    },

    getQuizById: async (quizId) => {
        return await customFetch(`/api/quizzes/${quizId}`, {
            method: 'GET',
        });
    },

    createQuiz: async (quiz) => {
        return await customFetch(`/api/quizzes`, {
            method: 'POST',
            body: JSON.stringify(quiz),
        });
    },

    updateQuiz: async (quizId, quiz) => {
        return await customFetch(`/api/quizzes/${quizId}`, {
            method: 'PUT',
            body: JSON.stringify(quiz),
        });
    },

    deleteQuiz: async (quizId) => {
        return await customFetch(`/api/quizzes/${quizId}`, {
            method: 'DELETE',
        });
    },
};

export default quizClient;
