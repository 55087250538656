import React, { useState } from 'react';
import { Button, Input, Select, message, Tabs } from 'antd';
import { ClearOutlined, CheckOutlined, PlusOutlined, DeleteOutlined, SearchOutlined, CloseOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import wordClient from "../../client/WordClient"; // Import wordClient
import dictionaryClient from "../../client/DictionaryClient"; // Import dictionaryClient
import { englishPartsOfSpeech } from '../../data/englishPartsOfSpeech';
import {spanishPartsOfSpeech} from "../../data/spanishPartsOfSpeech";
import {polishPartsOfSpeech} from "../../data/polishPartsOfSpeech";
import {chinesePartsOfSpeech} from "../../data/chinesePartsOfSpeech";
import {germanPartsOfSpeech} from "../../data/germanPartsOfSpeech";

const { TextArea } = Input;
const { Option } = Select;
const { TabPane } = Tabs;

const ContentAddWord = () => {
    const selectedDeck = useSelector((state) => state.deck.selectedDeck);
    const deckId = selectedDeck ? selectedDeck.deckId : null;
    const { locale } = useSelector((state) => state.deck.selectedDeck);
    const { translateLocale } = useSelector((state) => state.deck.selectedDeck);

    const getPartsOfSpeechOptions = () => {
        switch (locale.code) {
            case 'GERMAN':
                return germanPartsOfSpeech;
            case 'CHINESE_SIMPLIFIED':
                return chinesePartsOfSpeech;
            case 'POLISH':
                return polishPartsOfSpeech;
            case 'SPANISH_ES':
                return spanishPartsOfSpeech;
            default:
                return englishPartsOfSpeech; // Default to English if no locale matches
        }
    };

    // Determine if the transcription or pinyin input should be shown based on locale
    const shouldShowPhonetic = ['ENGLISH_GB', 'ENGLISH_US', 'CHINESE_SIMPLIFIED', 'CHINESE_TRADITIONAL'].includes(locale.code);


    const [word, setWord] = useState('');
    const [partOfSpeechEntries, setPartOfSpeechEntries] = useState([
        {
            partOfSpeech: '',
            word: '',
            phonetic: '',
            definitionsTranslations: [
                { definition: '', translation: '', examples: [{ sentence: '', translation: '' }] },
            ],
        },
    ]); // Initial state with one tab
    const [manualEntry, setManualEntry] = useState(true); // State to track if we're in manual or search mode
    const [loading, setLoading] = useState(false);
    const [searching, setSearching] = useState(false);

    const handleDefinitionTranslationChange = (tabIndex, index, field, value) => {
        const newPartOfSpeechEntries = [...partOfSpeechEntries];
        newPartOfSpeechEntries[tabIndex].definitionsTranslations[index][field] = value;
        setPartOfSpeechEntries(newPartOfSpeechEntries);
    };

    const handleAddDefinitionTranslation = (tabIndex) => {
        const newPartOfSpeechEntries = [...partOfSpeechEntries];
        newPartOfSpeechEntries[tabIndex].definitionsTranslations.push({ definition: '', translation: '', examples: [{ sentence: '', translation: '' }] });
        setPartOfSpeechEntries(newPartOfSpeechEntries);
    };

    const handleDeleteLastDefinitionTranslation = (tabIndex) => {
        const newPartOfSpeechEntries = [...partOfSpeechEntries];
        if (newPartOfSpeechEntries[tabIndex].definitionsTranslations.length > 1) {
            newPartOfSpeechEntries[tabIndex].definitionsTranslations.pop();
            setPartOfSpeechEntries(newPartOfSpeechEntries);
        }
    };

    const handleAddExample = (tabIndex, definitionIndex) => {
        const newPartOfSpeechEntries = [...partOfSpeechEntries];
        newPartOfSpeechEntries[tabIndex].definitionsTranslations[definitionIndex].examples.push({ sentence: '', translation: '' });
        setPartOfSpeechEntries(newPartOfSpeechEntries);
    };

    const handleDeleteExample = (tabIndex, definitionIndex) => {
        const newPartOfSpeechEntries = [...partOfSpeechEntries];
        if (newPartOfSpeechEntries[tabIndex].definitionsTranslations[definitionIndex].examples.length > 1) {
            newPartOfSpeechEntries[tabIndex].definitionsTranslations[definitionIndex].examples.pop();
            setPartOfSpeechEntries(newPartOfSpeechEntries);
        }
    };

    const handleExampleChange = (tabIndex, definitionIndex, exampleIndex, field, value) => {
        const newPartOfSpeechEntries = [...partOfSpeechEntries];
        newPartOfSpeechEntries[tabIndex].definitionsTranslations[definitionIndex].examples[exampleIndex][field] = value;
        setPartOfSpeechEntries(newPartOfSpeechEntries);
    };

    const handleClearForm = () => {
        setWord('');
        setPartOfSpeechEntries([
            {
                partOfSpeech: '',
                word: '',
                phonetic: '',
                definitionsTranslations: [
                    { definition: '', translation: '', examples: [{ sentence: '', translation: '' }] },
                ],
            },
        ]);
        setManualEntry(true);
    };

    const handleSearch = async () => {
        console.log("Locale:", locale); // Check if locale is defined
        console.log("translateLocale:", translateLocale);
        console.log("Word:", word);     // Check if word is defined
        setSearching(true);
        try {
            message.info('Searching...');
            const response = await dictionaryClient.getWord(word,locale.code,translateLocale.code);

            if (response && response.length > 0) {
                const newEntries = response.map((entry) => ({
                    partOfSpeech: entry.partOfSpeech,
                    word: entry.headword,

                    phonetic: locale.code.startsWith('ENGLISH' || 'CHINESE') ? entry.phonetic : '', // Only for English


                    definitionsTranslations: entry.wordDefinition.map((def) => ({
                        id: def.id,
                        definition: def.definition,
                        translation: def.translation,
                        examples: def.examples.map((ex) => ({
                            id: ex.id,
                            sentence: ex.sentence,
                            translation: ex.translation,
                        })),
                    })),
                }));
                setPartOfSpeechEntries(newEntries);
                setManualEntry(false);
            } else {
                message.warning('No results found.');
            }
        } catch (error) {
            console.error('Error fetching word data:', error);
            message.error('Error fetching word data.');
        } finally {
            setSearching(false);
        }
    };

    const handleSave = async () => {
        if (!deckId) {
            message.error('No deck selected (deckId).');
            return;
        }

        for (const entry of partOfSpeechEntries) {
            if (!entry.word.trim()) {
                message.error('The word cannot be empty.');
                return;
            }

            if (shouldShowPhonetic && !entry.phonetic.trim()) {
                message.error('The transcription cannot be empty.');
                return;
            }

            if (!entry.partOfSpeech.trim()) {
                message.error('Please select a part of speech.');
                return;
            }
        }

        const wordDataArray = partOfSpeechEntries.map((entry) => ({
            deckId: deckId,
            headword: entry.word,
            phonetic: entry.phonetic,
            partOfSpeech: entry.partOfSpeech,
            wordDefinition: entry.definitionsTranslations.map((item) => ({
                id: item.id,
                definition: item.definition,
                translation: item.translation,
                examples: item.examples.map((ex) => ({
                    id: ex.id,
                    sentence: ex.sentence,
                    translation: ex.translation,
                })),
            })),
        }));

        setLoading(true);
        try {
            for (const wordData of wordDataArray) {
                await wordClient.createWord(wordData);
            }
            message.success('Words successfully added.');
            handleClearForm();
        } catch (error) {
            console.error('Error saving the words:', error);
            message.error(`Error saving the words: ${error.message}`);
        } finally {
            setLoading(false);
        }
    };

    const handleRemoveTab = (tabIndex) => {
        const newEntries = [...partOfSpeechEntries];
        newEntries.splice(tabIndex, 1);
        setPartOfSpeechEntries(newEntries);
    };

    return (
        <div style={{ display: 'grid', gridTemplateColumns: '1fr', gap: '16px' }}>
            <div style={{ border: '1px solid #d9d9d9', padding: '16px', borderRadius: '8px' }}>
                <div style={{ display: 'flex', alignItems: 'center', gap: '8px', marginBottom: '6px' }}>
                    <Input
                        placeholder="Enter the word"
                        value={word}
                        onChange={(e) => setWord(e.target.value)}
                        style={{ flex: 1 }}
                    />
                    <Button
                        icon={<SearchOutlined />}
                        onClick={handleSearch}
                        loading={searching}
                    />
                </div>
            </div>

            <Tabs
                defaultActiveKey="0"
                tabBarStyle={partOfSpeechEntries.length <= 1 ? { display: 'none' } : {}}
            >
                {partOfSpeechEntries.map((entry, tabIndex) => (
                    <TabPane
                        tab={
                            <span>
                                {entry.partOfSpeech || 'New Entry'}
                                {partOfSpeechEntries.length > 1 && (
                                    <CloseOutlined
                                        onClick={() => handleRemoveTab(tabIndex)}
                                        style={{ fontSize: '10px', marginLeft: 8 }} // Adjust font size here
                                    />
                                )}
                            </span>
                        }
                        key={tabIndex}
                    >
                        <div
                            style={{
                                border: '1px solid #d9d9d9',
                                padding: '16px',
                                borderRadius: partOfSpeechEntries.length > 1 ? '0 0 8px 8px' : '8px',
                                borderTopLeftRadius: partOfSpeechEntries.length > 1 ? '0px' : '8px',
                                borderTopRightRadius: partOfSpeechEntries.length > 1 ? '0px' : '8px',
                                borderTop: partOfSpeechEntries.length > 1 ? '0px' : '1px solid #d9d9d9'
                            }}
                        >


                            <div style={{display: 'flex', alignItems: 'center', gap: '8px', marginBottom: '6px'}}>


                                <Select
                                    showSearch
                                    placeholder="Select part of speech"
                                    value={entry.partOfSpeech}
                                    onChange={(value) => {
                                        const newEntries = [...partOfSpeechEntries];
                                        newEntries[tabIndex].partOfSpeech = value;
                                        setPartOfSpeechEntries(newEntries);
                                    }}
                                    style={{width: '150px'}}
                                    options={getPartsOfSpeechOptions()}
                                />
                            </div>



                            <div style={{ marginBottom: '16px' }}>
                                {shouldShowPhonetic && (
                                    <Input
                                        placeholder="Enter the phonetic"
                                        value={entry.phonetic}
                                        onChange={(e) => {
                                            const newEntries = [...partOfSpeechEntries];
                                            newEntries[tabIndex].phonetic = e.target.value;
                                            setPartOfSpeechEntries(newEntries);
                                        }}
                                    />
                                )}


                            </div>







                            {entry.definitionsTranslations.map((item, index) => (
                                <div
                                    key={index}
                                    style={{
                                        border: '1px solid #d9d9d9',
                                        padding: '16px',
                                        borderRadius: '8px',
                                        marginBottom: '16px',
                                    }}
                                >
                                    <Input
                                        style={{marginBottom: '6px'}}
                                        placeholder={`Definition ${index + 1}`}
                                        value={item.definition}
                                        onChange={(e) => handleDefinitionTranslationChange(tabIndex, index, 'definition', e.target.value)}
                                    />
                                    <Input
                                        placeholder={`Translation for definition ${index + 1}`}
                                        value={item.translation}
                                        onChange={(e) => handleDefinitionTranslationChange(tabIndex, index, 'translation', e.target.value)}
                                        style={{marginBottom: '12px'}}
                                    />
                                    {item.examples.map((example, exampleIndex) => (
                                        <div key={exampleIndex} style={{marginBottom: '12px', paddingLeft: '16px'}}>
                                            <Input
                                                style={{marginBottom: '6px'}}
                                                placeholder={`Example ${exampleIndex + 1}`}
                                                value={example.sentence}
                                                onChange={(e) => handleExampleChange(tabIndex, index, exampleIndex, 'sentence', e.target.value)}
                                            />
                                            <Input
                                                placeholder={`Translation for example ${exampleIndex + 1}`}
                                                value={example.translation}
                                                onChange={(e) => handleExampleChange(tabIndex, index, exampleIndex, 'translation', e.target.value)}
                                            />
                                        </div>
                                    ))}
                                    <div style={{display: 'flex', justifyContent: 'space-between', marginTop: '12px'}}>
                                        <Button type="text" size="small"
                                                onClick={() => handleAddExample(tabIndex, index)}>
                                            + Add Example
                                        </Button>
                                        {item.examples.length > 1 && (
                                            <Button type="text" size="small" icon={<DeleteOutlined/>}
                                                    onClick={() => handleDeleteExample(tabIndex, index)}>
                                                Delete
                                            </Button>
                                        )}
                                    </div>
                                </div>
                            ))}
                            <div style={{display: 'flex', justifyContent: 'space-between', gap: '16px'}}>
                                <div>
                                    <Button
                                        icon={<PlusOutlined/>}
                                        onClick={() => handleAddDefinitionTranslation(tabIndex)}
                                        style={{marginRight: '8px'}}
                                    >
                                        Definition
                                    </Button>
                                    {entry.definitionsTranslations.length > 1 && (
                                        <Button
                                            icon={<DeleteOutlined/>}
                                            danger
                                            onClick={() => handleDeleteLastDefinitionTranslation(tabIndex)}
                                        >
                                            Definition
                                        </Button>
                                    )}
                                </div>
                            </div>
                        </div>
                    </TabPane>
                ))}
            </Tabs>

            <div style={{display: 'flex', justifyContent: 'space-between', gap: '16px'}}>
                <div style={{display: 'flex', gap: '16px'}}>
                    <Button
                        icon={<ClearOutlined/>}
                        onClick={handleClearForm}
                    >
                        Cancel
                    </Button>
                    <Button
                        type="primary"
                        icon={<CheckOutlined />}
                        loading={loading}
                        onClick={handleSave} // Call the handleSave function here
                    >
                        Save
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default ContentAddWord;
