import React, { useState, useEffect } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Button, Divider, Typography } from 'antd';
import { SoundOutlined, CaretDownOutlined, CaretUpOutlined, SoundFilled } from '@ant-design/icons';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import audioClient from "../../client/audioClient";

const { Text } = Typography;

function ChineseWordCard({
                      word,
                      handleCorrectClick,
                      handleIncorrectClick,
                      locale,
                  }) {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [showDefinition, setShowDefinition] = useState(false);
    const [openExamples, setOpenExamples] = useState({});
    const [audioUrl, setAudioUrl] = useState(null);
    const [exampleAudioCache, setExampleAudioCache] = useState({}); // Кеш аудио для примеров

    useEffect(() => {
        setShowDefinition(false);
        setOpenExamples({});
        setExampleAudioCache({}); // Очистка кеша при загрузке нового слова
    }, [word]);

    const toggleExamples = (index) => {
        setOpenExamples((prevState) => ({
            ...prevState,
            [index]: !prevState[index],
        }));
    };

    useEffect(() => {
        const fetchAudio = async () => {
            try {
                const audioBlob = await audioClient.getWordAudio({
                    word: word.headword,
                    partOfSpeech: word.partOfSpeech,
                    locale: locale.code,
                });
                const audioUrl = URL.createObjectURL(audioBlob);
                setAudioUrl(audioUrl);
            } catch (error) {
                console.error("Error fetching audio:", error);
            }
        };

        fetchAudio();

        return () => {
            if (audioUrl) {
                URL.revokeObjectURL(audioUrl);
            }
        };
    }, [word,locale]);

    useEffect(() => {
        if (audioUrl) {
            playAudio(audioUrl);
        }
    }, [audioUrl]);

    const playAudio = (url) => {
        if (url) {
            const audio = new Audio(url);
            audio.play().catch((error) => {
                console.error("Error playing audio:", error);
            });
        }
    };

    const playExampleAudio = async (uuid) => {
        if (exampleAudioCache[uuid]) {
            // Если аудио уже закешировано, проигрываем его
            playAudio(exampleAudioCache[uuid]);
        } else {
            // Если аудио нет в кеше, запрашиваем его
            try {
                const audioBlob = await audioClient.getWordAudio({
                    word: word.headword,
                    partOfSpeech: word.partOfSpeech,
                    locale,
                    uuid,
                });
                const exampleAudioUrl = URL.createObjectURL(audioBlob);

                // Сохраняем URL в кеш
                setExampleAudioCache((prevCache) => ({
                    ...prevCache,
                    [uuid]: exampleAudioUrl,
                }));

                // Воспроизводим аудио
                playAudio(exampleAudioUrl);
            } catch (error) {
                console.error("Error fetching example audio:", error);
            }
        }
    };

    return (
        <div
            style={{
                padding: isMobile ? '0' : '0px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                minHeight: 'calc(100vh - 80px)',
                overflow: 'hidden',
                width: isMobile ? '100%' : '33%',
                margin: isMobile ? '0' : 'auto',
            }}
            onClick={() => setShowDefinition(true)}
        >
            <Card style={{
                flex: '1 1 auto',
                display: 'flex',
                flexDirection: 'column',
                padding: '0px'
            }}>
                <CardContent style={{ flexGrow: 1, padding: isMobile ? '0px' : '20px' }}>

                    <div onClick={(event) => event.stopPropagation()}>
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: '0px' }}>
                            <Text strong style={{ fontSize: '2em', color: '#027b83', textAlign: 'center' }}>
                                {word.headword}
                            </Text>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <div
                                style={{
                                    borderRadius: '25%',
                                    padding: '3px',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    cursor: 'pointer',
                                    border: '1.5px solid #838383',
                                }}
                                onClick={(event) => {
                                    event.stopPropagation();
                                    playAudio(audioUrl);
                                }}
                            >
                                <SoundFilled style={{ fontSize: '0.9em', color: '#838383' }} />
                            </div>
                            <Text style={{ fontSize: '1em', marginLeft: '8px' }}>/{word.transcription}/</Text>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Text strong style={{ fontSize: '1.2em', marginTop: '8px', textAlign: 'center' }}>
                                {word.partOfSpeech}
                            </Text>
                        </div>
                    </div>

                    <Divider style={{ margin: isMobile ? '0px 0' : '10px 0' }} />

                    {showDefinition && (
                        <div style={{ marginTop: '10px' }}>
                            {word.wordDefinition.map((def, index) => (
                                <div key={index} style={{ marginBottom: '16px' }}>
                                    <div
                                        onClick={() => toggleExamples(index)}
                                        style={{
                                            cursor: 'pointer',
                                            display: 'flex',
                                            alignItems: 'center'
                                        }}
                                    >
                                        {openExamples[index] ?
                                            <CaretUpOutlined style={{ marginLeft: '8px', color: '#d9d9d9' }} /> :
                                            <CaretDownOutlined style={{ marginLeft: '8px', color: '#d9d9d9' }} />}

                                        <Text strong style={{ marginLeft: '8px' }}>{def.definition}</Text>
                                    </div>
                                    <div style={{ marginTop: '4px' }}>
                                        <Text italic strong style={{ marginLeft: '36px', color: '#ee6002' }}>
                                            {def.translation}
                                        </Text>
                                    </div>

                                    {openExamples[index] && (
                                        <div style={{ marginTop: '10px', marginLeft: '16px' }}>
                                            {def.examples.map((example, exampleIndex) => (
                                                <div key={exampleIndex} style={{ marginBottom: '8px' }}>
                                                    <div
                                                        style={{
                                                            border: '1px solid #027b83',
                                                            borderRadius: '25%',
                                                            padding: '3px',
                                                            display: 'inline-flex',
                                                            justifyContent: 'center',
                                                            alignItems: 'center',
                                                            cursor: 'pointer',
                                                            marginRight: '8px',
                                                        }}
                                                        onClick={(event) => {
                                                            event.stopPropagation();
                                                            playExampleAudio(example.id);
                                                        }}
                                                    >
                                                        <SoundFilled style={{ fontSize: '0.6em', color: '#027b83' }} />
                                                    </div>
                                                    <Text>{example.sentence}</Text>
                                                    <br />
                                                    <Text type="secondary">{example.translation}</Text>
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </div>
                            ))}
                        </div>
                    )}
                </CardContent>

                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        paddingTop: '6px',
                        paddingLeft: '20px',
                        paddingBottom: '20px',
                        paddingRight: '20px',
                    }}
                >
                    <Button
                        onClick={handleCorrectClick}
                        style={{ width: isMobile ? '100%' : '150px', marginRight: '10px' }}
                    >
                        Correct
                    </Button>
                    <Button
                        onClick={handleIncorrectClick}
                        style={{ width: isMobile ? '100%' : '150px' }}
                    >
                        Incorrect
                    </Button>
                </div>
            </Card>
        </div>
    );
}

export default ChineseWordCard;
