import React, { useState, useEffect } from 'react';
import { Button, Input, Checkbox, message, Modal } from 'antd';
import {
    PlusOutlined,
    CheckOutlined,
    DeleteOutlined,
    CloseOutlined,
} from '@ant-design/icons';

const { TextArea } = Input;

// Initial values for answers
const initialAnswers = [
    { text: '', isCorrect: false },
    { text: '', isCorrect: false },
    { text: '', isCorrect: false },
    { text: '', isCorrect: false },
];

const EditQuizForm = ({
                          quiz, // quiz data passed as props
                          deckId,
                          onSave, // Callback for saving quiz data (optional)
                          onCancel, // Callback for cancelling the form
                          onDelete, // Callback for deleting the quiz
                      }) => {
    // Local state for question, answers, answersNotDefinitive, correctAnswerCount, incorrectAnswerCount
    const [question, setQuestion] = useState('');
    const [answers, setAnswers] = useState([...initialAnswers]);
    const [answersNotDefinitive, setAnswersNotDefinitive] = useState(false);
    const [correctAnswerCount, setCorrectAnswerCount] = useState(0);
    const [incorrectAnswerCount, setIncorrectAnswerCount] = useState(0);

    // Load quiz data into local state when the component mounts or when the quiz changes
    useEffect(() => {
        const quizCopy = JSON.parse(JSON.stringify(quiz)); // Create a deep copy of quiz data each time the modal is opened
        setQuestion(quizCopy.question || '');
        setAnswers(quizCopy.answers || [...initialAnswers]);
        setAnswersNotDefinitive(quizCopy.answersNotDefinitive || false);
        setCorrectAnswerCount(quizCopy.correctAnswerCount || 0);
        setIncorrectAnswerCount(quizCopy.incorrectAnswerCount || 0);
    }, [quiz, onCancel]);

    // Handler for changing the answersNotDefinitive flag
    const handleCheckboxDefinitiveChange = () => {
        setAnswersNotDefinitive(!answersNotDefinitive);
    };

    // Handler for changing the answer text
    const handleAnswerChange = (index, value) => {
        const newAnswers = [...answers];
        newAnswers[index].text = value;
        setAnswers(newAnswers);
    };

    // Handler for changing the isCorrect flag for an answer
    const handleCheckboxChange = (index) => {
        const newAnswers = [...answers];
        newAnswers[index].isCorrect = !newAnswers[index].isCorrect;
        setAnswers(newAnswers);
    };

    // Handler for adding a new answer field
    const handleAddAnswer = () => {
        const newAnswers = [...answers, { text: '', isCorrect: false }];
        setAnswers(newAnswers);
    };

    // Handler for deleting the last answer field
    const handleDeleteAnswer = () => {
        if (answers.length > 4) {
            const newAnswers = answers.slice(0, -1);
            setAnswers(newAnswers);
        }
    };

    // Handler for clearing the form
    const handleClearForm = () => {
        setQuestion('');
        setAnswersNotDefinitive(false);
        setAnswers(
            initialAnswers.map((answer) => ({
                ...answer,
                text: '',
                isCorrect: false,
            }))
        );
        setCorrectAnswerCount(0);
        setIncorrectAnswerCount(0);
    };

    // Handler for saving the quiz (purely local, does not interact with Redux)
    const handleSave = () => {
        // Validation
        if (!question.trim()) {
            message.error('The question cannot be empty.');
            return;
        }

        const filledAnswers = answers.filter(
            (answer) => answer.text.trim() !== ''
        );

        if (filledAnswers.length < 2) {
            message.error('There must be at least two answers.');
            return;
        }

        if (!filledAnswers.some((answer) => answer.isCorrect)) {
            message.error('There must be at least one correct answer.');
            return;
        }

        const quizData = {
            question,
            answersNotDefinitive,
            answers: filledAnswers,
            correctAnswerCount,
            incorrectAnswerCount,
        };

        // Call the onSave callback with the quiz data (local)
        if (onSave) {
            onSave(quizData);
        }
    };

    // Handler for deleting the quiz
    const handleDeleteQuiz = () => {
        Modal.confirm({
            title: 'Are you sure you want to delete this quiz?',
            content: 'This action cannot be undone.',
            okText: 'Yes, Delete',
            okType: 'danger',
            cancelText: 'Cancel',
            onOk: () => {
                onDelete();
            },
        });
    };

    // Handler for cancelling (simply close the form without saving changes)
    const handleCancel = () => {
        onCancel(); // Close the form without saving any changes
    };

    return (
        <div
            style={{
                display: 'grid',
                gridTemplateColumns: '1fr',
                gap: '16px',
            }}
        >
            {/* Field for entering the question */}
            <TextArea
                rows={4}
                placeholder="Enter your question"
                value={question}
                onChange={(e) => setQuestion(e.target.value)}
            />

            {/* Fields for entering answers */}
            {answers.map((answer, index) => (
                <div key={index} style={{ display: 'flex', alignItems: 'center', gap: '8px', flexDirection: 'column' }}>
                    <div style={{ display: 'flex', alignItems: 'center', gap: '8px', width: '100%' }}>
                        <Checkbox
                            checked={answer.isCorrect}
                            onChange={() => handleCheckboxChange(index)}
                        />
                        <TextArea
                            rows={2}
                            placeholder={`Answer ${index + 1}`}
                            value={answer.text}
                            onChange={(e) => handleAnswerChange(index, e.target.value)}
                            style={{ flex: 1 }}
                        />
                    </div>
                    {index === answers.length - 1 && (
                        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                            <Button type="text" size="small" onClick={handleAddAnswer} style={{ marginLeft: '26px' }}>
                                + Answer
                            </Button>
                            {answers.length > 4 && (
                                <Button type="text" size="small" icon={<DeleteOutlined />} onClick={handleDeleteAnswer}>
                                    Delete
                                </Button>
                            )}
                        </div>
                    )}
                </div>
            ))}

            {/* Flag for non-definitive answers */}
            <Checkbox
                checked={answersNotDefinitive}
                onChange={handleCheckboxDefinitiveChange}
            >
                Answers are not definitive
            </Checkbox>

            {/* Fields for correct and incorrect counts in one line */}
            <div style={{ display: 'flex', gap: '16px', marginTop: '16px' }}>
                <Input
                    addonBefore="Correct"
                    value={correctAnswerCount}
                    onChange={(e) => setCorrectAnswerCount(e.target.value)}
                />
                <Input
                    addonBefore="Incorrect"
                    value={incorrectAnswerCount}
                    onChange={(e) => setIncorrectAnswerCount(e.target.value)}
                />
            </div>

            {/* Control buttons */}
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    gap: '16px',
                    flexWrap: 'wrap',
                    marginTop: '16px',
                }}
            >
                {/* Left: Delete button */}
                <Button
                    type="default"
                    danger
                    icon={<DeleteOutlined />}
                    onClick={handleDeleteQuiz}
                >
                    Delete Quiz
                </Button>

                {/* Right: Close and Save buttons */}
                <div style={{ display: 'flex', gap: '16px' }}>
                    <Button
                        icon={<CloseOutlined />}
                        onClick={handleCancel}
                        type="default"
                    >
                        Close
                    </Button>
                    <Button
                        type="primary"
                        icon={<CheckOutlined />}
                        onClick={handleSave}
                    >
                        Save
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default EditQuizForm;