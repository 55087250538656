import React, { useState } from 'react';
import { Button, Input, Checkbox, message } from 'antd';
import { PlusOutlined, ClearOutlined, CheckOutlined, DeleteOutlined } from '@ant-design/icons';
import quizClient from "../../client/QuizClient";
import { useSelector } from 'react-redux'; // Импорт useSelector для получения deckId из Redux

const { TextArea } = Input;

// Начальные значения для ответов
const initialAnswers = [
    { text: '', isCorrect: false },
    { text: '', isCorrect: false },
    { text: '', isCorrect: false },
    { text: '', isCorrect: false },
];

const ContentAddQuiz = () => {
    // Получение выбранной колоды из Redux Store
    const selectedDeck = useSelector((state) => state.deck.selectedDeck);
    const deckId = selectedDeck ? selectedDeck.deckId : null;

    // Состояния для вопроса, ответов, флага и загрузки
    const [question, setQuestion] = useState('');
    const [answers, setAnswers] = useState([...initialAnswers]);
    const [answersNotDefinitive, setAnswersNotDefinitive] = useState(false);
    const [loading, setLoading] = useState(false);

    // Обработчик изменения флага answersNotDefinitive
    const handleCheckboxDefinitiveChange = () => {
        setAnswersNotDefinitive(!answersNotDefinitive);
    };

    // Обработчик изменения текста ответа
    const handleAnswerChange = (index, value) => {
        const newAnswers = [...answers];
        newAnswers[index].text = value;
        setAnswers(newAnswers);
    };

    // Обработчик изменения флага isCorrect для ответа
    const handleCheckboxChange = (index) => {
        const newAnswers = [...answers];
        newAnswers[index].isCorrect = !newAnswers[index].isCorrect;
        setAnswers(newAnswers);
    };

    // Обработчик добавления нового викторины
    const handleAddQuestion = async () => {
        // Валидация вопроса
        if (!question.trim()) {
            message.error('The question cannot be empty.');
            return;
        }

        // Фильтрация заполненных ответов
        const filledAnswers = answers.filter(answer => answer.text.trim() !== '');

        // Валидация количества ответов
        if (filledAnswers.length < 2) {
            message.error('There must be at least two answers.');
            return;
        }

        // Валидация наличия хотя бы одного правильного ответа
        if (!filledAnswers.some(answer => answer.isCorrect)) {
            message.error('There must be at least one correct answer.');
            return;
        }

        // Валидация наличия deckId
        if (!deckId) {
            message.error('No deck selected (deckId).');
            return;
        }

        // Формирование данных для отправки
        const quizData = {
            deckId,
            question,
            answersNotDefinitive,
            answers: filledAnswers,
        };

        console.log('Submitting quiz data:', quizData); // Логирование для отладки

        setLoading(true);
        try {
            await quizClient.createQuiz(quizData);
            message.success('Quiz successfully added.');
            // Сброс формы после успешного добавления
            setQuestion('');
            setAnswersNotDefinitive(false);
            setAnswers(initialAnswers.map(answer => ({ ...answer, text: '', isCorrect: false })));
        } catch (error) {
            console.error('Error saving the quiz:', error);
            // Обработка ошибок
            message.error(`Error saving the quiz: ${error.message}`);
        } finally {
            setLoading(false);
        }
    };

    // Обработчик добавления нового поля для ответа
    const handleAddAnswer = () => {
        const newAnswers = [...answers, { text: '', isCorrect: false }];
        setAnswers(newAnswers);
    };

    // Обработчик удаления последнего поля для ответа
    const handleDeleteAnswer = () => {
        if (answers.length > 4) {
            const newAnswers = answers.slice(0, -1);
            setAnswers(newAnswers);
        }
    };

    // Обработчик очистки формы
    const handleClearForm = () => {
        setQuestion('');
        setAnswersNotDefinitive(false);
        setAnswers(initialAnswers.map(answer => ({ ...answer, text: '', isCorrect: false })));
    };

    return (
        <div style={{ display: 'grid', gridTemplateColumns: '1fr', gap: '16px' }}>
            {/* Поле для ввода вопроса */}
            <TextArea
                rows={4}
                placeholder="Enter your question"
                value={question}
                onChange={(e) => setQuestion(e.target.value)}
            />

            {/* Поля для ввода ответов */}
            {answers.map((answer, index) => (
                <div key={index} style={{ display: 'flex', alignItems: 'center', gap: '8px', flexDirection: 'column' }}>
                    <div style={{ display: 'flex', alignItems: 'center', gap: '8px', width: '100%' }}>
                        <Checkbox
                            checked={answer.isCorrect}
                            onChange={() => handleCheckboxChange(index)}
                        />
                        <TextArea
                            rows={2}
                            placeholder={`Answer ${index + 1}`}
                            value={answer.text}
                            onChange={(e) => handleAnswerChange(index, e.target.value)}
                            style={{ flex: 1 }}
                        />
                    </div>
                    {index === answers.length - 1 && (
                        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                            <Button type="text" size="small" onClick={handleAddAnswer} style={{ marginLeft: '26px' }}>
                                + Answer
                            </Button>
                            {answers.length > 4 && (
                                <Button type="text" size="small" icon={<DeleteOutlined />} onClick={handleDeleteAnswer}>
                                    Delete
                                </Button>
                            )}
                        </div>
                    )}
                </div>
            ))}

            {/* Флаг неопределённости ответов */}
            <Checkbox
                checked={answersNotDefinitive}
                onChange={handleCheckboxDefinitiveChange}
            >
                Answers are not definitive
            </Checkbox>

            {/* Кнопки управления формой */}
            <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '16px' }}>

                <Button
                    icon={<ClearOutlined />}
                    onClick={handleClearForm}
                >
                    Clear
                </Button>
                <Button
                    type="primary"
                    icon={<CheckOutlined />}
                    onClick={handleAddQuestion}
                    loading={loading}
                >
                    Quiz
                </Button>
            </div>
        </div>
    );
};

export default ContentAddQuiz;