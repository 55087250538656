import React from 'react';
import { List, Card, Space, Row, Col, Button, Tooltip } from 'antd';
import {
    EditOutlined,
    DeleteOutlined,
    SettingOutlined,
    RetweetOutlined,
} from '@ant-design/icons';

const DeckListDesktop = ({
                             decks,
                             loading,
                             selectedDeckId,
                             onEdit,
                             onDelete,
                             onSetup,
                             onRowClick,
                             renderVisibility,
                             localeOptions,
                             translateLocaleOptions
                         }) => {


    // Функция для получения флага по коду
    const getFlagByCode = (code, options) => {
        const language = options.find(option => option.code === code);
        return language ? language.flag : null;
    };

    return (
        <List
            grid={{ gutter: 16, column: 1 }}
            loading={loading}
            dataSource={decks}
            locale={{ emptyText: 'No available decks' }}
            renderItem={(deck) => {
                return (
                    <List.Item>
                        <Card
                            key={deck.deckId} // Добавляем ключ для принудительного обновления
                            bordered={selectedDeckId === deck.deckId}
                            onClick={() => onRowClick(deck)}
                            style={{
                                borderColor: selectedDeckId === deck.deckId ? '#02796b' : undefined,
                                width: '100%',
                                paddingLeft: '0px',
                                boxShadow: '0 0px 0px rgba(0, 0, 0, 0.1)',
                                borderRadius: '6px',
                                cursor: 'pointer',
                                backgroundColor: '#fff',
                            }}
                            hoverable
                        >
                            <Row gutter={16} align="middle">
                                {/* Заголовок и описание */}
                                <Col span={12}>
                                    <div>
                                        <h3 style={{ margin: '0 0 4px 0',
                                            fontSize: '26px',
                                            color: selectedDeckId === deck.deckId ? '#02796b' : '#8e949a',
                                        }}>
                                            {deck.title}
                                        </h3>
                                        <p style={{ margin: '0', fontStyle: 'italic', fontSize: '14px',
                                            color: selectedDeckId === deck.deckId ? '#000000' : '#8e949a',
                                           }}>
                                            {deck.description}
                                        </p>
                                    </div>
                                </Col>

                                {/* Тип, видимость и язык */}
                                <Col span={8}>
                                    <div style={{ marginBottom: '8px' }}>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <span style={{ fontWeight: 'bold', marginRight: '4px',color: selectedDeckId === deck.deckId ? '#000000' : '#8e949a',
                                            }}>Type:</span>
                                            <span style={{ fontSize: '14px',color: selectedDeckId === deck.deckId ? '#000000' : '#8e949a',
                                            }}>{deck.type}</span>
                                        </div>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <span style={{ fontWeight: 'bold', marginRight: '4px' ,
                                                color: selectedDeckId === deck.deckId ? '#000000' : '#8e949a',
                                            }}>Visibility:</span>
                                            <span style={{ fontSize: '14px',
                                                color: selectedDeckId === deck.deckId ? '#000000' : '#8e949a',
                                            }}>{renderVisibility(deck.visibility)}</span>
                                        </div>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <span style={{ fontWeight: 'bold', marginRight: '4px',color: selectedDeckId === deck.deckId ? '#000000' : '#8e949a',
                                            }}>Language:</span>
                                            <Tooltip title="Learning Language">
                                                {deck.locale ? (
                                                    <img
                                                        src={getFlagByCode(deck.locale.code, localeOptions)}
                                                        alt={deck.locale.name}
                                                        style={{
                                                            width: '20px',
                                                            height: '15px',
                                                            borderRadius: '3px',
                                                            marginRight: '4px',
                                                            filter: selectedDeckId === deck.deckId ? 'opacity(90%)' : 'grayscale(100%) opacity(75%)'
                                                    }}
                                                    />
                                                ) : (
                                                    <span style={{ color: '#808080', marginRight: '4px' }}>N/A</span>
                                                )}
                                            </Tooltip>

                                            {deck.translateLocale && deck.locale.code !== deck.translateLocale.code && (
                                                <>

                                            <RetweetOutlined style={{ color: '#808080', marginRight: '4px' }} />

                                            <Tooltip title="Translation Language">
                                                {deck.translateLocale ? (
                                                    <img
                                                        src={getFlagByCode(deck.translateLocale.code, translateLocaleOptions)}
                                                        alt={deck.translateLocale.name}
                                                        style={{
                                                            width: '20px',
                                                            height: '15px'
                                                            , borderRadius: '3px',
                                                            filter: selectedDeckId === deck.deckId ? 'opacity(90%)' : 'grayscale(100%) opacity(75%)'
                                                        }}
                                                    />
                                                ) : (
                                                    <span style={{ color: '#808080' }}>N/A</span>
                                                )}
                                            </Tooltip>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                </Col>

                                {/* Иконки действий */}
                                <Col span={4}>
                                    <Space size="small" align="center" style={{ justifyContent: 'flex-end', width: '100%' }}>
                                        <Button
                                            icon={<EditOutlined />}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                onEdit(deck);
                                            }}
                                            style={{
                                                fontSize: '16px',
                                                color: selectedDeckId === deck.deckId ? '#02796b' : '#808080',
                                            }}
                                        />
                                        <Button
                                            icon={<SettingOutlined />}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                onSetup(deck);
                                            }}
                                            style={{
                                                fontSize: '16px',
                                                color: selectedDeckId === deck.deckId ? '#02796b' : '#808080',
                                            }}
                                        />
                                        <Button
                                            icon={<DeleteOutlined />}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                onDelete(deck.deckId);
                                            }}
                                            style={{
                                                fontSize: '16px',
                                                color: selectedDeckId === deck.deckId ? '#8b0000' : '#808080',
                                            }}
                                        />
                                    </Space>
                                </Col>
                            </Row>
                        </Card>
                    </List.Item>
                );
            }}
        />
    );
};

export default DeckListDesktop;
