import React from 'react';
import { useSelector } from 'react-redux'; // Импортируем useSelector
import { Spin, message } from 'antd';
import QuizLearnPage from './QuizLearnPage';
import CardLearnPage from './CardLearnPage';
import WordLearnPage from "./WordLearnPage";

const LearnPage = () => {
    const selectedDeck = useSelector((state) => state.deck.selectedDeck);

    const isLoading = selectedDeck === undefined;

    if (isLoading) {
        return (
            <div style={{ textAlign: 'center', marginTop: '50px' }}>
                <Spin tip="Loading deck information..." size="large" />
            </div>
        );
    }

    if (selectedDeck === null) {
        return (
            <div style={{ textAlign: 'center', marginTop: '50px' }}>
                <p>Please select a deck to start learning.</p>
            </div>
        );
    }

    const selectedType = selectedDeck.type;

    const renderContentForm = () => {
        switch (selectedType) {
            case 'QUIZ':
                return <QuizLearnPage />;
            case 'CARD':
                return <CardLearnPage />;
            case 'WORD':
                return <WordLearnPage />;
            default:
                return <div>Please select a valid content type.</div>;
        }
    };

    return (
        <div style={{ width: '100%', paddingTop: '20px' }}>
            <div style={{ marginTop: '10px', width: '100%' }}>
                {renderContentForm()}
            </div>
        </div>
    );
};

export default LearnPage;
