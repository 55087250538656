// src/clients/profileClient.js

import customFetch from "../utils/fetchUtils";

const profileClient = {

    updateProfile: async (profileData) => {
        const formData = new FormData();

        // Добавляем текстовые поля только если они определены
        if (profileData.firstName !== undefined && profileData.firstName !== null) {
            formData.append('firstName', profileData.firstName);
        }

        if (profileData.lastName !== undefined && profileData.lastName !== null) {
            formData.append('lastName', profileData.lastName);
        }

        if (profileData.email !== undefined && profileData.email !== null) {
            formData.append('email', profileData.email);
        }

        // Добавляем файл аватара, если он существует
        if (profileData.avatar) {
            formData.append('avatar', profileData.avatar);
        }

        // Указываем responseType как 'text', чтобы избежать ошибки парсинга JSON
        return await customFetch(`/api/profile/update`, {
            method: 'POST',
            body: formData,
        }, 'text'); // Добавлен третий параметр 'text'
    },

    /**
     * Получает аватар пользователя.
     * @returns {Promise<string>} URL аватара пользователя.
     */
    getAvatar: async () => {
        try {
            // Указываем responseType как 'blob' для получения бинарных данных
            const blob = await customFetch(`/api/profile/avatar`, {
                method: 'GET',
                headers: {
                    'Accept': 'image/png,image/jpeg,image/jpg', // Указываем допустимые типы изображений
                },
            }, 'blob'); // Указываем, что ожидаем 'blob'

            console.log('Blob:', blob);

            // Проверка типа контента
            const contentType = blob.type;
            console.log('Content-Type:', contentType);

            // Создаем URL для отображения изображения
            const imageUrl = URL.createObjectURL(blob);
            console.log('Image URL:', imageUrl);
            return imageUrl;
        } catch (error) {
            console.error('Error in getAvatar:', error);
            throw error;
        }
    },
};

export default profileClient;
