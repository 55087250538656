// ThemeProvider.jsx
//
// https://ant.design/theme-editor#Style
//

import React from 'react';
import {ConfigProvider} from 'antd';

const ThemeProvider = ({children}) => (<ConfigProvider
        theme={{
            token: {
                colorPrimary: '#027b83', colorError: '#8B0000',
                fontFamily: 'SF Pro Text, sans-serif',

            }, components: {
                Button: {
                    colorPrimary: '#027b83',
                    colorPrimaryHover: '#0c5055',
                    colorError: '#8B0000',
                    colorErrorHover: '#660000',
                    defaultShadow: 'none',
                    primaryShadow: 'none',
                    errorShadow: 'none',
                    dangerShadow: 'none',
                    controlHeight: '32px',
                    borderRadius: '10px',


                }, Table: {
                    colorBgContainer: '#f9f9f9', colorText: '#333333', rowHoverBg: 'rgba(179,179,179,0.15)',
                },


                Menu: {
                    itemColor: '#8e949a', // Цвет иконок в списке
                    itemSelectedColor: '#027b83', // Цвет выделенных иконок в списке
                    // itemHoverColor: '#e30505', // Цвет при наведении на элемент списка
                    // itemActiveBg: '#d70505',
                    itemSelectedBg: '#e5f9f6'
                },

                Select: {
                    optionSelectedBg: '#E5F9F6FF',
                },

                Progress: {
                    colorInfo: '#027b83',
                    backgroundColor: '#027b83',
                    colorSuccess: '#027b83', // Задайте здесь нужный цвет для 100%


                },


                Icon: {
                    color: '#027b83', // Цвет иконок
                },

                Tabs: {
                    margin: '0px',
                    cardHeight: '32px',
                    cardPadding: '0px 16px',
                    itemSelectedColor: '#027b83',    // Цвет текста активной вкладки
                    itemHoverColor: '#0c5055',       // Цвет текста вкладок при наведении
                    itemColor: '#d9d9d9',          // Стандартный цвет текста вкладок
                    colorBgContainer: 'transparent', // Цвет фона вкладок по умолчанию
                    controlItemBgHover: 'red',
                    cardBg: 'transparent',
                    colorBorderSecondary: '#d9d9d9',
                    inkBarColor: '#027b83',          // Цвет полоски внизу для активной вкладки
                    controlHeight: '56',
                    boxShadowSecondary: 'none',
                },


            },
        }}
    >
        {children}
    </ConfigProvider>);

export default ThemeProvider;
