// src/client/CardClient.js

import customFetch from "../utils/fetchUtils";

const cardClient = {
    getAllCards: async () => {
        return await customFetch(`/api/cards`, {
            method: 'GET',
        });
    },

    getCardsByDeckId: async (deckId, page = 0, size = 10) => {
        return await customFetch(`/api/card-info/deck/${deckId}?page=${page}&size=${size}`, {
            method: 'GET',
        });
    },

    getCardById: async (cardId) => {
        return await customFetch(`/api/cards/${cardId}`, {
            method: 'GET',
        });
    },

    createCard: async (card) => {
        return await customFetch(`/api/cards`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(card),
        });
    },

    updateCard: async (cardId, card) => {
        return await customFetch(`/api/cards/${cardId}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(card),
        });
    },

    deleteCard: async (cardId) => {
        return await customFetch(`/api/cards/${cardId}`, {
            method: 'DELETE',
        });
    },
};

export default cardClient;
