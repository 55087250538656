export const polishPartsOfSpeech = [
    { value: 'noun', label: 'Rzeczownik' },
    { value: 'verb', label: 'Czasownik' },
    { value: 'adjective', label: 'Przymiotnik' },
    { value: 'adverb', label: 'Przysłówek' },
    { value: 'pronoun', label: 'Zaimek' },
    { value: 'preposition', label: 'Przyimek' },
    { value: 'conjunction', label: 'Spójnik' },
    { value: 'interjection', label: 'Wykrzyknik' },
    { value: 'particle', label: 'Partykuła' },           // Particle
    { value: 'numeral', label: 'Liczebnik' },            // Numeral
    { value: 'auxiliary', label: 'Czasownik posiłkowy' },// Auxiliary Verb
];
