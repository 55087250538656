import React, { useState, useEffect } from 'react';
import { useSelector } from "react-redux";
import { message, Spin, Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import EnglishGbWordCard from "../../components/WordCard/EnglishGbWordCard";
import practiceClient from "../../client/PracticeClient";
import GeneralWordCard from "../../components/WordCard/GeneralWordCard";
import ChineseWordCard from "../../components/WordCard/ChineseWordCard";

const WordRunPage = () => {
    const navigate = useNavigate();

    const { locale } = useSelector((state) => state.deck.selectedDeck);
    const { practiceId, practiceType } = useSelector((state) => state.practice);

    // Состояния для слов
    const [word, setWord] = useState(null);
    const [isLoading, setIsLoading] = useState(true); // Состояние загрузки
    const [error, setError] = useState(null);

    // Функция для получения слова
    const fetchWord = async (wordRequest) => {
        setIsLoading(true);
        try {
            const data = await practiceClient.submitPracticeResult(wordRequest);
            setWord(data);
            setIsLoading(false);
        } catch (err) {
            console.error("Error fetching word:", err);
            setError(err.message || 'Failed to fetch word.');
            setIsLoading(false);
        }
    };

    // Инициализация первого слова при монтировании страницы
    useEffect(() => {
        if (practiceId && practiceType) {
            // Первый запрос не содержит wordId и correct
            fetchWord({
                practiceId,
                practiceType,
            });
        } else {
            setError('Practice ID or Practice Type is missing.');
            setIsLoading(false);
        }
    }, [practiceId, practiceType]);


    useEffect(() => {
        if (error) {
            try {
                const parsedError = JSON.parse(error);
                if (parsedError.errorMessage === "No active questions were found.") {
                    setError("Congratulations: currently there are no cards available for learning!");
                }
            } catch (parseError) {
                // Ошибка не в формате JSON, обрабатываем как текст
            }
        }
    }, [error]);


    // Обработчики для кнопок "Correct" и "Incorrect"
    const handleCorrectClick = () => {
        if (!word) {
            message.error("No word available.");
            return;
        }
        fetchWord({
            practiceId,
            practiceType,
            wordId: word.id,
            correct: true,
        });
    };

    const handleIncorrectClick = () => {
        if (!word) {
            message.error("No word available.");
            return;
        }
        fetchWord({
            practiceId,
            practiceType,
            wordId: word.id,
            correct: false,
        });
    };

    let WordCardComponent;

    switch (locale.code) {
        case 'ENGLISH_GB':
            WordCardComponent = EnglishGbWordCard;
            break;
        case 'CHINESE_SIMPLIFIED':
            WordCardComponent = ChineseWordCard;
            break;
        default:
            WordCardComponent = GeneralWordCard; // TODO [][][] переделать на универсальную карту без транскрипции и пии в китайском
            break;
    }

    if (error) {
        return (
            <div style={{ textAlign: 'center', marginTop: '50px' }}>
                <p>{error}</p>
                <Button type="primary" onClick={() => navigate('/learn')}>
                    Go Back
                </Button>
            </div>
        );
    }



    return (
        <div style={{ position: 'relative' }}>
            {word ? (
                <WordCardComponent
                    word={word}
                    handleCorrectClick={handleCorrectClick}
                    handleIncorrectClick={handleIncorrectClick}
                    locale={locale}
                />
            ) : (
                <div style={{ textAlign: 'center', marginTop: '50px' }}>
                    <p>No word data available.</p>
                </div>
            )}

            {isLoading && (
                <div style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    background: 'rgba(255, 255, 255, 0.7)',
                    zIndex: 1000,
                }}>
                    <Spin tip="Loading word..." size="large" />
                </div>
            )}
        </div>
    );
};

export default WordRunPage;
