// src/components/Card/ContentAddCard.jsx

import React, { useRef, useState } from 'react';
import { Button, Grid, message } from 'antd';
import { ClearOutlined, CheckOutlined } from '@ant-design/icons';
import RichTextEditor from "../RichTextEditor/RichTextEditor";
import cardClient from "../../client/CardClient"; // Импорт CardClient
import { useSelector } from 'react-redux'; // Импорт useSelector для получения deckId из Redux

const { useBreakpoint } = Grid;

const ContentAddCard = () => {
    const [loading, setLoading] = useState(false);

    // Создаём рефы для редакторов
    const questionEditorRef = useRef(null);
    const answerEditorRef = useRef(null);

    // Получение выбранной колоды из Redux Store
    const selectedDeck = useSelector((state) => state.deck.selectedDeck);
    const deckId = selectedDeck ? selectedDeck.deckId : null;

    const handleClearForm = () => {
        // Вызываем метод clear для каждого редактора через рефы
        if (questionEditorRef.current) {
            questionEditorRef.current.clear();
        }
        if (answerEditorRef.current) {
            answerEditorRef.current.clear();
        }
    };

    const handleSave = async () => {
        // Получаем содержимое редакторов через рефы
        const questionContent = questionEditorRef.current ? questionEditorRef.current.getContent() : '';
        const answerContent = answerEditorRef.current ? answerEditorRef.current.getContent() : '';

        // Валидация данных
        if (!deckId) {
            message.error('No deck selected (deckId).');
            return;
        }

        if (!questionContent.trim()) {
            message.error('The question cannot be empty.');
            return;
        }

        if (!answerContent.trim()) {
            message.error('The answer cannot be empty.');
            return;
        }

        // Формирование данных для отправки
        const cardData = {
            deckId,
            question: questionContent,
            answer: answerContent,
            // Добавьте дополнительные поля, если необходимо
        };

        console.log('Submitting card data:', cardData); // Логирование для отладки

        setLoading(true);
        try {
            await cardClient.createCard(cardData);
            message.success('Card successfully added.');
            // Сброс формы после успешного добавления
            handleClearForm();
        } catch (error) {
            console.error('Error saving the card:', error);
            // Обработка ошибок
            message.error(`Error saving the card: ${error.message}`);
        } finally {
            setLoading(false);
        }
    };

    const screens = useBreakpoint();
    const isMobile = !screens.md; // Предполагаем, что брейкпоинт md соответствует мобильным устройствам

    return (
        <div style={{
            display: 'grid',
            gridTemplateColumns: '1fr',
            gap: '16px',
            width: isMobile ? '100%' : '100%',
            margin: '0 auto'
        }}>
            {/* Блок для вопроса и ответа */}
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '12px',
            }}>
                <RichTextEditor
                    ref={questionEditorRef} // Привязываем реф
                    placeholder="Enter your Question here..."
                    minHeight={50}
                />
                <RichTextEditor
                    ref={answerEditorRef} // Привязываем реф
                    placeholder="Enter your Answer here..."
                    minHeight={150}
                />

                {/* Uncomment the line below if you need a read-only editor */}
                {/* <RichTextEditor readOnly={true} /> */}
            </div>

            {/* Кнопки управления формой */}
            <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '16px' }}>
                <Button icon={<ClearOutlined />} onClick={handleClearForm}>
                    Cancel
                </Button>
                <Button type="primary" icon={<CheckOutlined />} loading={loading} onClick={handleSave}>
                    Save
                </Button>
            </div>
        </div>
    );
};

export default ContentAddCard;
