// AppRoutes.jsx
import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import Layout from "./layout/Layout";
import AuthLayout from "./layout/AuthLayout";
import LoginPage from "./pages/auth/LoginPage";
import RegistrationPage from "./pages/auth/RegistrationPage";
import ConfirmEmailPage from "./pages/auth/ConfirmEmailPage";
import DashboardPage from "./pages/DashboardPage/DashboardPage";
import DeckPage from "./pages/DeckPage/DeckPage";
import ContentPage from "./pages/ContentPage/ContentPage";
import ListPage from "./pages/ListPage/ListPage";
import LearnPage from "./pages/LearnPage/LearnPage";
import QuizRunPage from "./pages/QuizRunPage/QuizRunPage";
import CardRunPage from "./pages/CardRunPage/CardRunPage";
import { useSelector } from "react-redux";
import ProfilePage from "./pages/ProfilePage/ProfilePage";
import WordRunPage from "./pages/WordRunPage/WordRunPage";
import PresetPage from "./pages/PresetPage/PresetPage";

const AppRoutes = () => {
    const isLoggedIn = useSelector(state => state.auth.isLoggedIn);

    return (
        <Routes>
            {isLoggedIn ? (
                <Route path="/" element={<Layout />}>
                    <Route index element={<DeckPage />} />
                    <Route path="set" element={<DashboardPage />} />
                    <Route path="deck" element={<DeckPage />} />
                    <Route path="content" element={<ContentPage />} />
                    <Route path="list" element={<ListPage />} />
                    <Route path="learn" element={<LearnPage />} />
                    <Route path="quiz-run" element={<QuizRunPage />} />
                    <Route path="card-run" element={<CardRunPage />} />
                    <Route path="word-run" element={<WordRunPage />} />
                    <Route path="/profile" element={<ProfilePage />} />
                    <Route path="preset" element={<PresetPage />} />


                    <Route path="*" element={<Navigate to="/" />} />
                </Route>
            ) : (
                <Route path="/" element={<AuthLayout />}>
                    <Route index element={<LoginPage />} />
                    <Route path="login" element={<LoginPage />} />
                    <Route path="registration" element={<RegistrationPage />} />
                    <Route path="confirm-email" element={<ConfirmEmailPage />} />
                    {/* Другие маршруты, связанные с аутентификацией */}
                    <Route path="*" element={<Navigate to="/login" />} />
                </Route>
            )}
        </Routes>
    );
};

export default AppRoutes;
