import React, { useEffect, forwardRef, useImperativeHandle } from 'react';
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import { LexicalErrorBoundary } from '@lexical/react/LexicalErrorBoundary';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import ToolbarPlugin from './plugins/ToolbarPlugin';
import ExampleTheme from './themes/RichTextEditorTheme';
import './RichTextEditor.css';
import { v4 as uuidv4 } from 'uuid';
import PropTypes from 'prop-types';
import { $getRoot } from 'lexical';

// Плагин для управления режимом только для чтения
const ReadOnlyPlugin = ({ readOnly }) => {
    const [editor] = useLexicalComposerContext();

    useEffect(() => {
        editor.setEditable(!readOnly);
    }, [editor, readOnly]);

    return null;
};

ReadOnlyPlugin.propTypes = {
    readOnly: PropTypes.bool.isRequired,
};

// Внутренний компонент для управления рефом и вставки начального контента
const RichTextEditorInner = forwardRef(({ initialContent }, ref) => {
    const [editor] = useLexicalComposerContext();

    useEffect(() => {
        if (initialContent) {
            // Вставляем данные в редактор
            editor.update(() => {
                const editorState = editor.parseEditorState(initialContent); // Если данные в формате JSON
                editor.setEditorState(editorState);
            });
        }
    }, [initialContent, editor]);

    useImperativeHandle(ref, () => ({
        clear: () => {
            editor.update(() => {
                const root = $getRoot();
                root.clear();
            });
        },
        getContent: () => {
            let content = '';
            editor.getEditorState().read(() => {
                content = JSON.stringify(editor.getEditorState().toJSON());
            });
            return content;
        },
    }));

    return null;
});

RichTextEditorInner.propTypes = {
    initialContent: PropTypes.string, // Пропс для начального контента
};

// Основной компонент RichTextEditor
const RichTextEditor = forwardRef(({
                                       namespace,
                                       readOnly = false,
                                       textColor = '#000',
                                       placeholder = 'Enter text...',
                                       minHeight = 0,
                                       fontSize = '15px',
                                       initialContent = null, // Новый пропс для начального контента
                                   }, ref) => {
    const editorConfig = {
        namespace: namespace || uuidv4(),
        nodes: [],
        onError(error) {
            throw error;
        },
        theme: ExampleTheme,
        editable: !readOnly,
    };

    return (
        <LexicalComposer initialConfig={editorConfig}>
            <div className="editor-container" style={{ color: textColor }}>
                {!readOnly && <ToolbarPlugin />}
                <div className="editor-inner">
                    <RichTextPlugin
                        contentEditable={<ContentEditable
                            className="editor-input"
                            style={{ minHeight: `${minHeight}px`, fontSize: fontSize }}
                        />}
                        placeholder={<div className="editor-placeholder">{placeholder}</div>}
                        ErrorBoundary={LexicalErrorBoundary}
                    />
                    <HistoryPlugin />
                    <ReadOnlyPlugin readOnly={readOnly} />
                    <RichTextEditorInner ref={ref} initialContent={initialContent} /> {/* Передаем initialContent */}
                </div>
            </div>
        </LexicalComposer>
    );
});

RichTextEditor.propTypes = {
    namespace: PropTypes.string,
    readOnly: PropTypes.bool,
    textColor: PropTypes.string,
    placeholder: PropTypes.string,
    minHeight: PropTypes.number,
    fontSize: PropTypes.string,
    initialContent: PropTypes.string, // Добавляем новый PropTypes для начального контента
};

export default RichTextEditor;
