import React, { useState, useEffect, useRef } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Button, Divider } from 'antd';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import RichTextEditor from "../RichTextEditor/RichTextEditor";

function CardCard({
                      card,
                      handleCorrectClick,
                      handleIncorrectClick,
                  }) {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const textFontSize = isMobile ? 15 : 20;
    const [showAnswer, setShowAnswer] = useState(false);
    const [limitHeight, setLimitHeight] = useState(true); // Состояние для ограничения высоты
    const answerRef = useRef(null); // Реф для доступа к контейнеру с ответом

    // Функция для отображения ответа при клике
    const handleShowAnswer = () => {
        setShowAnswer(true);
    };

    // Reset showAnswer when a new card is received
    useEffect(() => {
        setShowAnswer(false);
        setLimitHeight(true); // Optionally reset other states if needed
    }, [card]);

    // Используем эффект, чтобы проверить высоту ответа после его отображения
    useEffect(() => {
        if (showAnswer && answerRef.current) {
            const availableHeight = window.innerHeight - 80; // Высота доступного пространства (100vh - 80px)
            const answerHeight = answerRef.current.scrollHeight; // Высота контента

            // Если высота ответа больше, чем доступное пространство, убираем ограничение по высоте
            if (answerHeight > availableHeight) {
                setLimitHeight(false); // Убираем ограничение
            }
        }
    }, [showAnswer, isMobile]);

    return (
        <div
            style={{
                padding: isMobile ? '0' : '0px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                minHeight: 'calc(100vh - 80px)', // Устанавливаем минимальную высоту
                height: limitHeight ? 'auto' : 'auto', // Делаем высоту авто
                overflow: 'hidden', // Отключаем скроллинг
            }}
            onClick={handleShowAnswer} // Показываем ответ при клике на экран
        >
            <Card style={{
                flex: '1 1 auto',
                display: 'flex',
                flexDirection: 'column',
                padding: '0px'
            }}>
                <CardContent style={{ flexGrow: 1, padding: isMobile ? '0px' : '20px' }}>
                    {/* RichTextEditor для отображения вопроса */}
                    <RichTextEditor
                        readOnly={true}
                        initialContent={card.question}
                        fontSize={textFontSize}
                    />

                    {/* Разделитель */}
                    <Divider style={{ margin: isMobile ? '0px 0' : '10px 0'  }} />

                    {/* RichTextEditor для отображения ответа, который скрыт до нажатия */}
                    {showAnswer && (
                        <div ref={answerRef}>
                            <RichTextEditor
                                readOnly={true}
                                textColor="#6E6E6E"
                                initialContent={card.answer}
                                fontSize={textFontSize}
                            />
                        </div>
                    )}
                </CardContent>

                {/* Кнопки внизу по центру */}
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        paddingTop: '6px',
                        paddingLeft: '20px',
                        paddingBottom: '20px',
                        paddingRight: '20px',

                    }}
                >
                    <Button
                        onClick={handleCorrectClick}
                        style={{ width: isMobile ? '100%' : '150px', marginRight: '10px' }}
                        disabled={false} // Optionally disable during loading
                    >
                        Correct
                    </Button>
                    <Button
                        onClick={handleIncorrectClick}
                        style={{ width: isMobile ? '100%' : '150px' }}
                        disabled={false} // Optionally disable during loading
                    >
                        Incorrect
                    </Button>
                </div>
            </Card>
        </div>
    );
}

export default CardCard;
