import React, { useEffect, useRef } from 'react';
import { Menu } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { logout } from '../../redux/actions/authActions';
import { useNavigate } from 'react-router-dom';
import { toggleUserProfile } from '../../redux/actions/profileActions'; // Импорт нового экшена

const isMobile = window.innerWidth <= 768;

const styles = {
    profileDropdown: {
        position: 'absolute',
        right: isMobile ? '0px' : '20px',
        top: isMobile ? '42px' : '42px',
        backgroundColor: 'white',
      //  boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
        borderRadius: isMobile ? '0px' : '16px',
        padding: '10px',
        height: isMobile ? 'calc(100vh - 52px)' : 'auto', // Высота до конца экрана за вычетом отступа сверху на мобильных
        overflowY: 'auto', // Добавление прокрутки на мобильных, если контент больше высоты экрана
    },
    userInfo: {
        paddingBottom: '10px',
        padding: '10px',
        marginBottom: '10px',
        borderBottom: '2px dashed #e0e0e0',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
    },
    username: {
        fontWeight: 'bold',
        color: '#333',
        marginBottom: '5px',
    },
    email: {
        color: '#555',
        fontSize: '0.9rem',
    },
};

function UserProfile() {
    const { familyName, givenName, email } = useSelector(state => state.profile);
    const dispatch = useDispatch();
    const ref = useRef(null); // Создаем ref для компонента UserProfile

    const navigate = useNavigate();

    // Функция для обработки клика вне компонента
    const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
            dispatch(toggleUserProfile()); // Закрыть UserProfile, если клик вне компонента
        }
    };

    // Добавление и удаление обработчика событий
    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleActionClick = (action) => {
        if (action === 'logout') {
            dispatch(logout());
        }
        if (action === 'profile') {
            navigate('/profile');
        }
        dispatch(toggleUserProfile());
    };

    return (
        <div ref={ref} style={styles.profileDropdown}> {/* Установка ref для компонента */}
            <div style={styles.userInfo}>
                <div style={styles.username}>{givenName} {familyName}</div>
                <div style={styles.email}>{email}</div>
            </div>
            <Menu
                style = {{borderInline: 'none'}}
                selectable={false}
                mode="vertical"
                onClick={({ key }) => handleActionClick(key)}

            >
                <Menu.Item key="profile">
                    Profile
                </Menu.Item>
                <Menu.Item key="settings">
                    Settings
                </Menu.Item>
                <Menu.Item key="logout">
                    Logout
                </Menu.Item>
            </Menu>
        </div>
    );
}

export default UserProfile;