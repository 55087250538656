import React from 'react';
import { Grid } from '@mui/material';
import { Outlet } from 'react-router-dom';
import authImage from '../assets/pages/auth/auth2.webp';

const AuthLayout = () => {
    return (
        <Grid container style={{ minHeight: '100vh' }}>
            <Grid item xs={false} sm={6} md={6}
                  style={{
                      backgroundImage: `url(${authImage})`,
                      backgroundRepeat: 'no-repeat',
                      backgroundSize: 'cover',
                      backgroundPosition: 'center',
                      display: { xs: 'none', sm: 'block' } // Скрытие изображения на мобильных устройствах
                  }}
            >
                {/* Картинка слева, скрыта на xs размерах */}
            </Grid>
            <Grid item xs={12} sm={6} md={6}
                  display="flex"
                  flexDirection="column"
                  justifyContent="flex-start" // Выравнивание содержимого чуть выше центра
                  alignItems="center"
            >
                <Outlet /> {/* Дочерние маршруты будут отображаться здесь */}
            </Grid>
        </Grid>
    );
};

export default AuthLayout;
