// QuizLearnPage.js
import React, { useState, useEffect } from 'react';
import { Card, Button, Typography, Slider, Row, Col, Grid, Form, message, Tooltip, Space } from 'antd';
import { useSelector, useDispatch } from "react-redux";
import { CloudSyncOutlined, SettingOutlined } from '@ant-design/icons'; // Импортируем RefreshOutlined
import { useNavigate } from 'react-router-dom'; // Импорт useNavigate
import practiceClient from "../../client/PracticeClient";
import { setPractice } from '../../redux/actions/practiceActions';

const { Title, Paragraph, Text } = Typography;
const { useBreakpoint } = Grid;

const QuizLearnPage = () => {

    const dispatch = useDispatch(); // Инициализация dispatch
    const navigate = useNavigate(); // Инициализация navigate

    // Получаем полный объект selectedDeck из Redux
    const selectedDeck = useSelector((state) => state.deck.selectedDeck);

    // Состояние для карточки Practice
    const [poolDTO, setPoolDTO] = useState({
        startQuizNumber: 1,
        finishQuizNumber: 14, // Инициализируем с 1 по 14 как пример
        poolSize: 5, // Пример выбранного значения Pool Size
        correctAnswerLevel: 5,
        totalQuizzes: 0, // Инициализируем с 0, будет обновлено после инициализации
    });

    // Состояние для карточки Real
    const [realDTO, setRealDTO] = useState({
        examDuration: 120, // Время экзамена в минутах
        numberOfQuestions: 65, // Количество вопросов
        questionRange: [1, 290], // Диапазон номеров вопросов
    });

    const [isLoading, setIsLoading] = useState(false);

    // Состояния для отслеживания режима настройки
    const [isRealSettingsActive, setIsRealSettingsActive] = useState(false);
    const [isPracticeSettingsActive, setIsPracticeSettingsActive] = useState(false);

    // Состояние для хранения первоначального значения finishQuizNumber из API
    const [initialFinishQuizNumber, setInitialFinishQuizNumber] = useState(null);

    // Состояние для хранения первоначальных значений до начала настройки (для "Cancel")
    const [initialPoolDTO, setInitialPoolDTO] = useState(null);

    const screens = useBreakpoint();
    const isMobile = !screens.md;

    const handleRangeSliderChange = (value) => {
        const [newStart, newFinish] = value;

        const newQuizRangeSize = newFinish - newStart + 1;

        const updatedPoolSize = Math.min(poolDTO.poolSize, newQuizRangeSize);

        setPoolDTO((prevState) => ({
            ...prevState,
            startQuizNumber: newStart,
            finishQuizNumber: newFinish,
            // Меняем размер poolSize, если текущее значение выходит за пределы диапазона
            poolSize: updatedPoolSize,
        }));
    };

    const handleSliderChange = (value, sliderName) => {
        setPoolDTO((prevState) => ({
            ...prevState,
            [sliderName]: value,
        }));
    };

    const handleRealInputChange = (value, field) => {
        setRealDTO((prevState) => ({
            ...prevState,
            [field]: value,
        }));
    };

    const handleRealRangeChange = (value) => {
        setRealDTO((prevState) => ({
            ...prevState,
            questionRange: value,
        }));
    };

    // Обработчик для инициализации практики без learnDetails
    const handleInitializePractice = async (practiceType) => {
        if (!selectedDeck || !selectedDeck.deckId) {
            message.error('No deck selected.');
            return;
        }

        setIsLoading(true);
        const practiceRequest = {
            deckId: selectedDeck.deckId,
            practiceType: practiceType,
        };

        try {
            const response = await practiceClient.initializePractice(practiceRequest.practiceType, practiceRequest);

            const learnDetails = response.learnDetails;

            setPoolDTO({
                startQuizNumber: learnDetails.quizRangeStart,
                finishQuizNumber: learnDetails.quizRangeEnd,
                poolSize: learnDetails.poolSize,
                correctAnswerLevel: learnDetails.requiredCorrectAnswers,
                totalQuizzes: learnDetails.totalQuizzes, // Устанавливаем totalQuizzes из ответа
            });

            setRealDTO((prevState) => ({
                ...prevState,
                questionRange: [learnDetails.quizRangeStart, learnDetails.quizRangeEnd],
                numberOfQuestions: learnDetails.poolSize,
            }));

            // Сохраняем первоначальное значение finishQuizNumber
            setInitialFinishQuizNumber(learnDetails.quizRangeEnd);

            // Сохраняем исходные значения poolDTO для отмены
            setInitialPoolDTO({
                startQuizNumber: learnDetails.quizRangeStart,
                finishQuizNumber: learnDetails.quizRangeEnd,
                poolSize: learnDetails.poolSize,
                correctAnswerLevel: learnDetails.requiredCorrectAnswers,
            });

            // Сохраняем данные в practiceReducer
            dispatch(setPractice(response.practiceId, practiceType));

        } catch (error) {
            console.error('Error initializing practice:', error);
            message.error('Failed to initialize practice.');
        } finally {
            setIsLoading(false);
        }
    };

    // Обработчик для применения новых настроек с learnDetails
    const handleApplyPracticeSettings = async () => {
        if (!selectedDeck || !selectedDeck.deckId) {
            message.error('No deck selected.');
            return;
        }

        setIsLoading(true);

        const practiceType = 'LEARN_QUIZ'; // Или получить из состояния, если нужно

        const deckType = selectedDeck.deckType || 'QUIZ'; // Предполагаем, что deckType доступен в selectedDeck

        const practiceRequest = {
            deckId: selectedDeck.deckId,
            practiceType: practiceType,
            deckType: deckType,
            learnDetails: {
                poolSize: poolDTO.poolSize,
                requiredCorrectAnswers: poolDTO.correctAnswerLevel,
                quizRangeStart: poolDTO.startQuizNumber,
                quizRangeEnd: poolDTO.finishQuizNumber,
            },
        };

        try {
            const response = await practiceClient.initializePractice(practiceRequest.practiceType, practiceRequest); // Используем тот же метод

            const learnDetails = response.learnDetails;

            setPoolDTO({
                startQuizNumber: learnDetails.quizRangeStart,
                finishQuizNumber: learnDetails.quizRangeEnd,
                poolSize: learnDetails.poolSize,
                correctAnswerLevel: learnDetails.requiredCorrectAnswers,
                totalQuizzes: learnDetails.totalQuizzes, // Обновляем totalQuizzes из ответа
            });

            setRealDTO((prevState) => ({
                ...prevState,
                questionRange: [learnDetails.quizRangeStart, learnDetails.quizRangeEnd],
                numberOfQuestions: learnDetails.poolSize,
            }));

            // Обновляем первоначальные значения после применения настроек
            setInitialPoolDTO({
                startQuizNumber: learnDetails.quizRangeStart,
                finishQuizNumber: learnDetails.quizRangeEnd,
                poolSize: learnDetails.poolSize,
                correctAnswerLevel: learnDetails.requiredCorrectAnswers,
            });

            // Сохраняем данные в practiceReducer
            dispatch(setPractice(response.practiceId, practiceType));

            message.success('Practice settings applied successfully.');

        } catch (error) {
            console.error('Error applying practice settings:', error);
            message.error('Failed to apply practice settings.');
        } finally {
            setIsLoading(false);
            setIsPracticeSettingsActive(false);
        }
    };

    // Вызов функции инициализации при загрузке страницы
    useEffect(() => {
        const defaultPracticeType = 'LEARN_QUIZ'; // Или 'PRACTICE', если нужно
        handleInitializePractice(defaultPracticeType);
    }, []);

    // Функции для включения/выключения режима настройки
    const handleToggleRealSettings = () => {
        setIsRealSettingsActive(!isRealSettingsActive);
    };

    const handleTogglePracticeSettings = () => {
        if (!isPracticeSettingsActive) {
            // Сохраняем текущие настройки перед входом в режим настройки
            setInitialPoolDTO(poolDTO);
        } else {
            // Если режим настройки деактивируется без применения, сбрасываем изменения
            if (initialPoolDTO) {
                setPoolDTO(initialPoolDTO);
                setRealDTO((prevState) => ({
                    ...prevState,
                    questionRange: [initialPoolDTO.startQuizNumber, initialPoolDTO.finishQuizNumber],
                    numberOfQuestions: initialPoolDTO.poolSize,
                }));
                message.info('Practice settings changes have been canceled');
            }
        }
        setIsPracticeSettingsActive(!isPracticeSettingsActive);
    };

    // Функции для Apply и Cancel
    const handleApplySettings = () => {
        handleApplyPracticeSettings();
    };

    const handleCancelPracticeSettings = () => {
        // Восстанавливаем значения до изменений
        if (initialPoolDTO) {
            setPoolDTO(initialPoolDTO);
            setRealDTO((prevState) => ({
                ...prevState,
                questionRange: [initialPoolDTO.startQuizNumber, initialPoolDTO.finishQuizNumber],
                numberOfQuestions: initialPoolDTO.poolSize,
            }));
            setIsPracticeSettingsActive(false);
            message.info('Practice settings have been canceled');
        }
    };

    // Функция для обновления настроек Practice до первоначальных значений
    const handleRefreshPracticeSettings = () => {
        if (initialPoolDTO) {
            setPoolDTO(initialPoolDTO);
            setRealDTO((prevState) => ({
                ...prevState,
                questionRange: [initialPoolDTO.startQuizNumber, initialPoolDTO.finishQuizNumber],
                numberOfQuestions: initialPoolDTO.poolSize,
            }));
            message.success('Practice settings have been refreshed');
        } else {
            message.info('No initial settings available to refresh.');
        }
    };

    return (
        <div style={{ padding: '10px', display: 'flex', justifyContent: 'center' }}>
            <Row
                gutter={[isMobile ? 30 : 30, 30]}
                justify="center"
                align="top"
                style={{ width: '100%', display: 'flex', alignItems: 'stretch'}}
            >

                {/* Practice Card */}
                <Col xs={24} sm={12} md={8}>
                    <Card
                        bordered
                        style={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            height: '100%',
                            position: 'relative'
                        }}
                    >
                        {/* Добавляем иконки Refresh и Settings */}
                        <div style={{ position: 'absolute', top: 10, right: 10, display: 'flex', alignItems: 'center' }}>
                            <Tooltip title="Refresh Settings" placement="topRight">
                                <CloudSyncOutlined
                                    style={{ marginRight: '8px', fontSize: '20px', cursor: 'pointer', color: '#808080' }}
                                    onClick={handleRefreshPracticeSettings}
                                />
                            </Tooltip>
                            <Tooltip title="Setup Practice" placement="topRight">
                                <SettingOutlined
                                    style={{ fontSize: '20px', cursor: 'pointer', color: '#808080' }}
                                    onClick={handleTogglePracticeSettings}
                                />
                            </Tooltip>
                        </div>

                        <div style={{ flex: '1 0 auto', paddingTop: '0px' }}>
                            <Title level={3}>Practice</Title>
                            <Paragraph>
                                Your study pool consists of {poolDTO.poolSize} questions. A question is considered learned once it reaches {poolDTO.correctAnswerLevel} correct answers. Questions added to the study pool are selected from questions {poolDTO.startQuizNumber} to {poolDTO.finishQuizNumber}.
                            </Paragraph>

                            {/* Pool Size Slider */}
                            <div style={{ marginTop: '20px', textAlign: 'left' }}>
                                <Text>Pool Size: {poolDTO.poolSize}</Text>
                                <Slider
                                    min={1}
                                    max={poolDTO.finishQuizNumber - poolDTO.startQuizNumber + 1} // Динамически устанавливаем максимальное значение
                                    step={1}
                                    value={poolDTO.poolSize}
                                    onChange={(value) => handleSliderChange(value, 'poolSize')}
                                    tooltip={{ formatter: (value) => `${value}` }}
                                    disabled={!isPracticeSettingsActive}
                                />
                            </div>

                            {/* Correct Answer Level Slider */}
                            <div style={{ marginTop: '20px', textAlign: 'left' }}>
                                <Text>Correct Answer Level: {poolDTO.correctAnswerLevel}</Text>
                                <Slider
                                    min={1}
                                    max={15}
                                    step={1}
                                    value={poolDTO.correctAnswerLevel}
                                    onChange={(value) => handleSliderChange(value, 'correctAnswerLevel')}
                                    tooltip={{ formatter: (value) => `${value}` }}
                                    disabled={!isPracticeSettingsActive}
                                />
                            </div>

                            {/* Quiz Range Slider */}
                            <div style={{ marginTop: '20px', textAlign: 'left' }}>
                                <Text>Quiz Range from {poolDTO.startQuizNumber} to {poolDTO.finishQuizNumber}</Text>
                                <Slider
                                    range
                                    min={1}
                                    max={poolDTO.totalQuizzes || 14} // Используем totalQuizzes из состояния
                                    value={[poolDTO.startQuizNumber, poolDTO.finishQuizNumber]}
                                    onChange={handleRangeSliderChange}
                                    tooltip={{ formatter: (value) => `${value}` }}
                                    disabled={!isPracticeSettingsActive}
                                />
                            </div>
                        </div>
                        <div style={{ marginTop: '20px' }}>
                            {isPracticeSettingsActive ? (
                                <Space style={{ width: '100%', justifyContent: 'center' }}>
                                    <Button type="primary" onClick={handleApplySettings}>
                                        Apply
                                    </Button>
                                    <Button onClick={handleCancelPracticeSettings}>
                                        Cancel
                                    </Button>
                                </Space>
                            ) : (
                                <Button
                                    type="primary"
                                    block
                                    onClick={() => navigate('/quiz-run')} // Переход на другую страницу
                                    loading={isLoading}
                                >
                                    Let's practice!
                                </Button>
                            )}
                        </div>
                    </Card>
                </Col>

                {/* Real Card */}
                <Col xs={24} sm={12} md={8}>
                    <Card
                        bordered
                        style={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            height: '100%',
                            position: 'relative'
                        }}
                    >
                        <Tooltip title="Setup Real Exam" placement="topRight">
                            <SettingOutlined
                                style={{ position: 'absolute', top: 10, right: 10, fontSize: '20px', cursor: 'pointer', color: '#808080' }}
                                onClick={handleToggleRealSettings}
                            />
                        </Tooltip>

                        <div style={{ flex: '1 0 auto' }}>
                            <Title level={3}>Real</Title>
                            <Paragraph>
                                65 Questions in 2 hours: a simulation close to a real exam.
                            </Paragraph>

                            <Form layout="vertical" style={{ marginTop: '20px' }}>
                                <Form.Item label="Exam Duration (minutes)" required>
                                    <Slider
                                        min={10}
                                        max={300}
                                        step={5}
                                        value={realDTO.examDuration}
                                        onChange={(value) => handleRealInputChange(value, 'examDuration')}
                                        tooltip={{ formatter: (value) => `${value} min` }}
                                        disabled={!isRealSettingsActive}
                                    />

                                </Form.Item>

                                <Form.Item label="Number of Questions" required>
                                    <Slider
                                        min={1}
                                        max={poolDTO.poolSize || 100}
                                        step={1}
                                        value={realDTO.numberOfQuestions}
                                        onChange={(value) => handleRealInputChange(value, 'numberOfQuestions')}
                                        tooltip={{ formatter: (value) => `${value} question${value !== 1 ? 's' : ''}` }}
                                        disabled={!isRealSettingsActive}
                                    />

                                </Form.Item>

                                <Form.Item label="Question Range">
                                    <Slider
                                        range
                                        min={1}
                                        max={poolDTO.totalQuizzes || 290} // Используем totalQuizzes из состояния
                                        value={realDTO.questionRange}
                                        onChange={handleRealRangeChange}
                                        tooltip={{ formatter: (value) => `${value}` }}
                                        disabled={!isRealSettingsActive}
                                    />

                                </Form.Item>
                            </Form>
                        </div>
                        <div style={{ marginTop: '20px' }}>
                            {isRealSettingsActive ? (
                                <Space style={{ width: '100%', justifyContent: 'center' }}>
                                    <Button type="primary" onClick={() => setIsRealSettingsActive(false)}>
                                        Apply
                                    </Button>
                                    <Button onClick={() => setIsRealSettingsActive(false)}>
                                        Cancel
                                    </Button>
                                </Space>
                            ) : (
                                <Button
                                    block
                                    onClick={() => message.info('Starting real exam...')}
                                    loading={isLoading}
                                >
                                    Start Real Exam
                                </Button>
                            )}
                        </div>
                    </Card>
                </Col>


            </Row>
        </div>
    );
};

export default QuizLearnPage;
