import React, { useState, useEffect } from 'react';
import { useSelector } from "react-redux";
import { message, Spin, Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import CardCard from "../../components/CardCard/CardCard";
import practiceClient from "../../client/PracticeClient";

const CardRunPage = () => {
    const navigate = useNavigate();

    // Получаем practiceId и practiceType из Redux
    const { practiceId, practiceType } = useSelector((state) => state.practice);

    // Состояния для карточек
    const [card, setCard] = useState(null);
    const [isLoading, setIsLoading] = useState(true); // Состояние загрузки
    const [error, setError] = useState(null);

    // Функция для получения карточки
    const fetchCard = async (cardRequest) => {
        setIsLoading(true);
        try {
            const data = await practiceClient.submitPracticeResult(cardRequest);
            setCard(data);
            setIsLoading(false);
        } catch (err) {
            console.error("Error fetching card:", err);
            setError(err.message || 'Failed to fetch card.');
            setIsLoading(false);
        }
    };

    // Инициализация первой карточки при монтировании страницы
    useEffect(() => {
        if (practiceId && practiceType) {
            // Первый запрос не содержит cardId и correct
            fetchCard({
                practiceId,
                practiceType,
            });
        } else {
            setError('Practice ID or Practice Type is missing.');
            setIsLoading(false);
        }
    }, [practiceId, practiceType]);

    // Обработчики для кнопок "Correct" и "Incorrect"
    const handleCorrectClick = () => {
        if (!card) {
            message.error("No card available.");
            return;
        }
        fetchCard({
            practiceId,
            practiceType,
            cardId: card.id,
            correct: true,
        });
    };

    const handleIncorrectClick = () => {
        if (!card) {
            message.error("No card available.");
            return;
        }
        fetchCard({
            practiceId,
            practiceType,
            cardId: card.id,
            correct: false,
        });
    };

    if (error) {
        return (
            <div style={{ textAlign: 'center', marginTop: '50px' }}>
                <p>Error: {error}</p>
                <Button type="primary" onClick={() => navigate('/')}>
                    Go Back
                </Button>
            </div>
        );
    }

    return (
        <div style={{ position: 'relative' }}>
            {card ? (
                <CardCard
                    card={card}
                    handleCorrectClick={handleCorrectClick}
                    handleIncorrectClick={handleIncorrectClick}
                />
            ) : (
                <div style={{ textAlign: 'center', marginTop: '50px' }}>
                    <p>No card data available.</p>
                </div>
            )}

            {isLoading && (
                <div style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    background: 'rgba(255, 255, 255, 0.7)',
                    zIndex: 1000,
                }}>
                    <Spin tip="Loading card..." size="large" />
                </div>
            )}
        </div>
    );
};

export default CardRunPage;
