// actions/profileActions.js

export const TOGGLE_USER_PROFILE = 'TOGGLE_USER_PROFILE';
export const SET_USER_DATA = 'SET_USER_DATA'; // Новый тип действия
export const SET_USER_AVATAR = 'SET_USER_AVATAR'; // Добавленная константа


export const toggleUserProfile = () => {
    return {
        type: TOGGLE_USER_PROFILE
    };
};

// Экшен для сохранения данных пользователя
export const setUserData = (userData) => {
    return {
        type: SET_USER_DATA,
        payload: userData
    };
};

export const setUserAvatar = (avatarUrl) => {
    return {
        type: SET_USER_AVATAR,
        payload: avatarUrl
    };
};