import { logout } from '../redux/actions/authActions';
import store from "../redux/store";
import userPool from "./userPool"; // Убедитесь, что путь корректный

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

/**
 * Получает заголовок авторизации.
 * @returns {Object} Заголовок авторизации или пустой объект.
 */
const getAuthHeader = async () => {
    const cognitoUser = userPool.getCurrentUser();
    if (cognitoUser) {
        try {
            const session = await new Promise((resolve, reject) => {
                cognitoUser.getSession((err, session) => err ? reject(err) : resolve(session));
            });
            const accessToken = session.getAccessToken().getJwtToken();
            return { 'Authorization': `Bearer ${accessToken}` };
        } catch (error) {
            console.error('Ошибка при обновлении access token:', error);
            return {};
        }
    }
    return {};
};

/**
 * Выполняет HTTP-запрос с возможностью указания типа ожидаемого ответа.
 * @param {string} url - URL эндпоинта.
 * @param {Object} options - Опции запроса (метод, тело и т.д.).
 * @param {string} responseType - Тип ожидаемого ответа ('json', 'blob', 'text').
 * @returns {Promise<Object|Blob|string|null>} Ответ запроса.
 */
const customFetch = async (url, options = {}, responseType = 'json') => {
    const headers = await getAuthHeader().catch(() => ({})); // Получаем headers асинхронно
    const fullUrl = `${API_BASE_URL}${url}`;

    let finalHeaders = {
        ...headers,
        ...options.headers,
    };

    // Если тело запроса не является FormData, устанавливаем Content-Type
    if (!(options.body instanceof FormData)) {
        finalHeaders['Content-Type'] = 'application/json';
    }

    try {
        const response = await fetch(fullUrl, { ...options, headers: finalHeaders });

        if (!response.ok) {
            if (response.status === 401) {
                store.dispatch(logout());
            }
            const errorText = await response.text();
            throw new Error(errorText || `HTTP error! status: ${response.status}`);
        }

        if (response.headers.get("content-length") === "0" || response.status === 204) {
            return null;
        }

        // Обрабатываем ответ в зависимости от типа данных
        switch (responseType) {
            case 'json':
                return await response.json();
            case 'blob':
                return await response.blob();
            case 'text':
                return await response.text();
            default:
                return response;
        }
    } catch (error) {
        console.error('Fetch error:', error);
        throw error;
    }
};

export default customFetch;
