import customFetch from "../utils/fetchUtils";

const audioClient = {
    getWordAudio: async ({ word, partOfSpeech, locale, uuid }) => {
        // Формируем тело запроса
        const requestBody = { word, partOfSpeech, locale };

        // Добавляем uuid только если оно существует
        if (uuid) {
            requestBody.uuid = uuid;
        }

        // Передаем responseType как 'blob' для обработки бинарных данных (аудио)
        return await customFetch(`/api/audio/word`, {
            method: 'POST',
            body: JSON.stringify(requestBody),
            headers: {
                'Content-Type': 'application/json',
            },
        }, 'blob');
    },
};

export default audioClient;
