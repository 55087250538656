import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import quizClient from '../../client/QuizClient';
import QuizInfoCard from '../../components/QuizInfoCard/QuizInfoCard';
import { Pagination, Spin, message, Row } from 'antd';

const QuizList = () => {
    // Получаем полный объект selectedDeck из Redux
    const selectedDeck = useSelector((state) => state.deck.selectedDeck);

    // Извлекаем deckId из selectedDeck, если он существует
    const deckId = selectedDeck ? selectedDeck.deckId : null;

    // Состояния компонента
    const [quizzes, setQuizzes] = useState([]);
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalQuizzes, setTotalQuizzes] = useState(0);
    const pageSize = 10; // Количество квизов на странице

    useEffect(() => {
        // Сброс текущей страницы при изменении deckId
        setCurrentPage(1);

        if (deckId) {
            fetchQuizzes(deckId, 1, pageSize);
        } else {
            // Если колода не выбрана, очистить квизы и общее количество
            setQuizzes([]);
            setTotalQuizzes(0);
        }
    }, [deckId]);

    useEffect(() => {
        if (deckId) {
            fetchQuizzes(deckId, currentPage, pageSize);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage]);

    const fetchQuizzes = async (deckId, page, size) => {
        setLoading(true);
        try {
            const response = await quizClient.getQuizzesByDeckId(deckId, page - 1, size);
            console.log('Quizzes fetched:', response);
            setQuizzes(response.content);
            setTotalQuizzes(response.totalElements);
        } catch (error) {
            console.error('Error fetching quizzes:', error);
            message.error('Не удалось загрузить квизы.');
        } finally {
            setLoading(false);
        }
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    // Обработка случаев, когда selectedDeck не выбран
    if (!selectedDeck) {
        return (
            <div style={{ textAlign: 'center', marginTop: '50px' }}>
                <p>Пожалуйста, выберите колоду для просмотра квизов.</p>
            </div>
        );
    }

    return (
        <div style={{ width: '100%', padding: '0px' }}>
            {loading ? (
                <div style={{ textAlign: 'center', marginTop: '50px' }}>
                    <Spin tip="Загрузка квизов..." size="large" />
                </div>
            ) : (
                <>
                    {quizzes && quizzes.length > 0 ? (
                        quizzes.map((quiz) => (
                            <QuizInfoCard
                                key={quiz.id}
                                quiz={quiz}
                            />
                        ))
                    ) : (
                        <div style={{ textAlign: 'center', marginTop: '50px' }}>
                            <p>Квизы для этой колоды отсутствуют.</p>
                        </div>
                    )}
                    {totalQuizzes > pageSize && (
                        <Row justify="center" style={{ marginTop: '20px' }}>
                            <Pagination
                                current={currentPage}
                                pageSize={pageSize}
                                total={totalQuizzes}
                                onChange={handlePageChange}
                            />
                        </Row>
                    )}
                </>
            )}
        </div>
    );
};

export default QuizList;
