export const chinesePartsOfSpeech = [
    { value: 'noun', label: '名词' },                // Noun (míngcí)
    { value: 'verb', label: '动词' },                // Verb (dòngcí)
    { value: 'adjective', label: '形容词' },          // Adjective (xíngróngcí)
    { value: 'adverb', label: '副词' },              // Adverb (fùcí)
    { value: 'pronoun', label: '代词' },             // Pronoun (dàicí)
    { value: 'preposition', label: '介词' },         // Preposition (jiècí)
    { value: 'conjunction', label: '连词' },         // Conjunction (liáncí)
    { value: 'interjection', label: '感叹词' },       // Interjection (gǎntàncí)
    { value: 'classifier', label: '量词' },          // Classifier/Measure Word (liàngcí)
    { value: 'particle', label: '助词' },            // Particle (zhùcí)
    { value: 'numeral', label: '数词' },             // Numeral (shùcí)
];
