// src/components/DeckListMobile.js

import React from 'react';
import { List, Card, Tooltip, Progress } from 'antd'; // Удалён Space, добавлен Progress
import {
    EditOutlined,
    DeleteOutlined,
    SettingOutlined,
    RetweetOutlined
} from '@ant-design/icons';

const DeckListMobile = ({
                            decks,
                            loading,
                            selectedDeckId,
                            onEdit,
                            onDelete,
                            onSetup,
                            onRowClick,
                            renderVisibility,
                            localeOptions,
                            translateLocaleOptions
                        }) => {

    // Функция для получения флага по коду
    const getFlagByCode = (code, options) => {
        const language = options.find(option => option.code === code);
        return language ? language.flag : null;
    };

    return (
        <List
            grid={{ gutter: 8, column: 1 }}
            loading={loading}
            dataSource={decks}
            locale={{ emptyText: 'No available decks' }}
            renderItem={deck => (
                <List.Item>
                    <Card
                        title={<span style={{ fontSize: '20px', color: selectedDeckId === deck.deckId ? '#02796b' : '#8e949a' }}>{deck.title}</span>}
                        bordered={selectedDeckId === deck.deckId}
                        onClick={() => onRowClick(deck)}

                        style={{
                            borderColor: selectedDeckId === deck.deckId ? '#02796b' : undefined, // Изменён цвет границы
                            cursor: 'pointer',
                            body: {
                                paddingLeft: '24px',
                                paddingTop: '12px',
                                paddingBottom: '12px',
                            }

                        }}
                        actions={[
                            <Tooltip title="Edit" key="edit">
                                <EditOutlined
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        onEdit(deck);
                                    }}
                                    style={{
                                        fontSize: '16px',
                                        color: selectedDeckId === deck.deckId ? '#02796b' : '#808080',
                                    }}
                                />
                            </Tooltip>,
                            <Tooltip title="Setup" key="setup">
                                <SettingOutlined
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        onSetup(deck);
                                    }}
                                    style={{
                                        fontSize: '16px',
                                        color: selectedDeckId === deck.deckId ? '#02796b' : '#808080',
                                    }}
                                />
                            </Tooltip>,
                            <Tooltip title="Delete" key="delete">
                                <DeleteOutlined
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        onDelete(deck.deckId);
                                    }}
                                    style={{
                                        fontSize: '16px',
                                        color: selectedDeckId === deck.deckId ? '#8b0000' : '#808080',
                                    }}
                                />
                            </Tooltip>,
                        ]}

                    >
                        {/* Уменьшены отступы для описания */}
                        <div>
                            <p style={{ padding: '0px', margin: '0px',color: selectedDeckId === deck.deckId ? '#000000' : '#8e949a',
                            }}>{deck.description}</p>
                            <p style={{ margin: '0px', paddingTop: '12px',color: selectedDeckId === deck.deckId ? '#000000' : '#8e949a',
                            }}><strong>Type:</strong> {deck.type}</p>
                            <p style={{ margin: '0px', padding: '0px',color: selectedDeckId === deck.deckId ? '#000000' : '#8e949a',
                            }}><strong>Visibility:</strong> {renderVisibility(deck.visibility)}</p>
                            <div style={{ display: 'flex', alignItems: 'center', paddingTop: '6px' }}>
                                <span style={{ fontWeight: 'bold', marginRight: '4px',color: selectedDeckId === deck.deckId ? '#000000' : '#8e949a',
                                }}>Language:</span>
                                <Tooltip title="Learning Language">
                                    {deck.locale ? (
                                        <img
                                            src={getFlagByCode(deck.locale.code, localeOptions)}
                                            alt={deck.locale.name}
                                            style={{
                                                width: '20px',
                                                height: '15px',
                                                borderRadius: '3px',
                                                marginRight: '4px',
                                                filter: selectedDeckId === deck.deckId ? 'opacity(90%)' : 'grayscale(100%) opacity(75%)'
                                            }}
                                        />
                                    ) : (
                                        <span style={{ color: '#808080', marginRight: '4px' }}>N/A</span>
                                    )}
                                </Tooltip>
                                {deck.translateLocale && deck.locale.code !== deck.translateLocale.code && (
                                    <>

                                        <RetweetOutlined style={{ color: '#808080', marginRight: '4px' }} />

                                        <Tooltip title="Translation Language">
                                            {deck.translateLocale ? (
                                                <img
                                                    src={getFlagByCode(deck.translateLocale.code, translateLocaleOptions)}
                                                    alt={deck.translateLocale.name}
                                                    style={{
                                                        width: '20px',
                                                        height: '15px'
                                                        , borderRadius: '3px',
                                                        filter: selectedDeckId === deck.deckId ? 'opacity(90%)' : 'grayscale(100%) opacity(75%)'
                                                    }}
                                                />
                                            ) : (
                                                <span style={{ color: '#808080' }}>N/A</span>
                                            )}
                                        </Tooltip>
                                    </>
                                )}
                            </div>
                        </div>
                    </Card>
                </List.Item>
            )}
        />
    );
};

// Устанавливаем значение по умолчанию для progress, если оно отсутствует
DeckListMobile.defaultProps = {
    decks: []
};

export default DeckListMobile;
