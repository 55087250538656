import React from 'react';
import { useSelector } from 'react-redux';
import { Spin, message } from 'antd';
import QuizList from '../../components/QuizList/QuizList';
import CardList from "../../components/CardList/CardList";
import WordList from "../../components/WordList/WordList";

const ListPage = () => {
    const selectedDeck = useSelector((state) => state.deck.selectedDeck);


    if (selectedDeck === undefined) {
        return (
            <div style={{ textAlign: 'center', marginTop: '50px' }}>
                <Spin tip="Loading deck information..." size="large" />
            </div>
        );
    }

    if (selectedDeck === null) {
        return <div>Please select a deck to view content.</div>;
    }

    const selectedType = selectedDeck.type;

    const renderContentForm = () => {
        switch (selectedType) {
            case 'QUIZ':
                return <QuizList />;
            case 'CARD':
                return <CardList />;
            case 'WORD':
                return <WordList/>;
            default:
                return <div>Please select a valid content type</div>;
        }
    };

    return (
        <div style={{ width: '100%', padding: '0px' }}>
            <div style={{ marginTop: '10px', width: '100%' }}>
                {renderContentForm()}
            </div>
        </div>
    );
};

export default ListPage;
