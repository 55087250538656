// src/redux/reducers/profileReducer.js

import { TOGGLE_USER_PROFILE, SET_USER_DATA, SET_USER_AVATAR } from '../actions/profileActions';

const initialState = {
    isProfileOpen: false,
    familyName: '', // Новое поле для фамилии
    givenName: '', // Новое поле для имени
    email: '',
    avatar: null // Новое поле для аватара (URL изображения)
};

const profileReducer = (state = initialState, action) => {
    switch (action.type) {
        case TOGGLE_USER_PROFILE:
            return {
                ...state,
                isProfileOpen: !state.isProfileOpen
            };
        case SET_USER_DATA: // Обработка нового действия
            return {
                ...state,
                familyName: action.payload.familyName,
                givenName: action.payload.givenName,
                email: action.payload.email
            };
        case SET_USER_AVATAR: // Обработка нового действия для аватара
            return {
                ...state,
                avatar: action.payload
            };
        default:
            return state;
    }
};

export default profileReducer;
