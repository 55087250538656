import customFetch from "../utils/fetchUtils";

const wordClient = {
    getAllWords: async () => {
        return await customFetch(`/api/words`, {
            method: 'GET',
        });
    },

    getWordsByDeckId: async (deckId, page = 0, size = 10) => {
        return await customFetch(`/api/words/deck/${deckId}?page=${page}&size=${size}`, {
            method: 'GET',
        });
    },

    getWordById: async (wordId) => {
        return await customFetch(`/api/words/${wordId}`, {
            method: 'GET',
        });
    },

    createWord: async (word) => {
        return await customFetch(`/api/words`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(word),
        });
    },

    updateWord: async (wordId, word) => {
        return await customFetch(`/api/words/${wordId}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(word),
        });
    },

    deleteWord: async (wordId) => {
        return await customFetch(`/api/words/${wordId}`, {
            method: 'DELETE',
        });
    },
};

export default wordClient;
