// utils/authUtils.js
import userPool from "./userPool";
import { logout } from "../redux/actions/authActions";
import { toggleUserProfile } from "../redux/actions/profileActions";

export const getAccessToken = (dispatch) => {
    return new Promise((resolve, reject) => {
        const cognitoUser = userPool.getCurrentUser();
        if (cognitoUser != null) {
            cognitoUser.getSession((err, session) => {
                if (err) {
                    if (err.message === "NotAuthorizedException: Refresh Token has expired") {
                        dispatch(logout());
                        dispatch(toggleUserProfile());
                    }
                    reject(err);
                } else if (session.isValid()) {
                    resolve(session.getAccessToken().getJwtToken());
                } else {
                    reject(new Error("Session is not valid"));
                }
            });
        } else {
            reject(new Error("No current user found"));
        }
    });
};
