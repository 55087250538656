import React, { useState } from 'react';
import { TextField, Button, Checkbox, FormControlLabel, Typography, Box } from '@mui/material';
import { CognitoUserAttribute } from "amazon-cognito-identity-js";
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from "react-toastify";
import UserPool from "../../utils/userPool";
import 'react-toastify/dist/ReactToastify.css';


const RegistrationPage = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [givenName, setGivenName] = useState('');
    const [familyName, setFamilyName] = useState('');
    const [acceptTerms, setAcceptTerms] = useState(false);

    const navigate = useNavigate();

    const handleSubmit = (event) => {
        event.preventDefault();

        if (password !== confirmPassword) {
            toast.error("Passwords do not match");
            return;
        }

        if (!acceptTerms) {
            toast.error("You must accept the terms and conditions");
            return;
        }

        UserPool.signUp(email, password, [
            new CognitoUserAttribute({ Name: "email", Value: email }),
            new CognitoUserAttribute({ Name: "given_name", Value: givenName }),
            new CognitoUserAttribute({ Name: "family_name", Value: familyName }),
        ], null, (err, result) => {
            if (err) {
                toast.error(`Registration failed: ${err.message}`);
                return;
            }
            toast.success("Registration successful. Please check your email to confirm your account.");
            navigate("/confirm-email", { state: { email: email } });
        });
    };

    return (
        <Box sx={{ maxWidth: '400px', width: '80%', margin: 'auto', padding: '2rem' }}>
            <ToastContainer />
            <Typography variant="h4" gutterBottom textAlign="center">
                Create Account
            </Typography>
            <form onSubmit={handleSubmit} noValidate>
                <TextField
                    label="First Name"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    value={givenName}
                    onChange={(e) => setGivenName(e.target.value)}
                />
                <TextField
                    label="Last Name"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    value={familyName}
                    onChange={(e) => setFamilyName(e.target.value)}
                />
                <TextField
                    label="Email Address"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    autoComplete="email"
                />
                <TextField
                    label="Password"
                    type="password"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    autoComplete="new-password"
                />
                <TextField
                    label="Confirm Password"
                    type="password"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    autoComplete="new-password"
                />
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={acceptTerms}
                            onChange={(e) => setAcceptTerms(e.target.checked)}
                        />
                    }
                    label="I accept the terms and conditions"
                />
                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                    sx={{ mt: 2 }}
                >
                    Create Account
                </Button>
            </form>
        </Box>
    );
};

export default RegistrationPage;
