// ProfilePage.jsx

import React, { useState } from 'react';
import { Card, Avatar, Form, Input, Button, Typography, Space, Upload, message } from 'antd';
import {
    UserOutlined,
    LockOutlined,
    DeleteOutlined,
    EditOutlined,
    CameraOutlined,
    CloseOutlined,
} from '@ant-design/icons';
import ImageCropModal from "../../components/UI/Modals/ImageCropModal";
import profileClient from "../../client/ProfileClient";

import { useDispatch, useSelector } from 'react-redux';
import { setUserData, setUserAvatar } from "../../redux/actions/profileActions";


function base64ToFile(base64String, fileName) {
    const arr = base64String.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], fileName, { type: mime });
}




const { Paragraph } = Typography;

const ProfilePage = () => {
    // Extracting profile data from Redux
    const { givenName, familyName, email } = useSelector((state) => state.profile);
    const dispatch = useDispatch(); // Инициализация dispatch

    // Form instance
    const [form] = Form.useForm();

    // States for image handling
    const [image, setImage] = useState(null);
    const [croppedImage, setCroppedImage] = useState(() => localStorage.getItem('profileImage') || null);
    const [previousImage, setPreviousImage] = useState(croppedImage); // To store the previous avatar
    const [modalVisible, setModalVisible] = useState(false);
    const [isChanged, setIsChanged] = useState(false); // Track if any changes were made

    // Initial form values
    const [initialFirstName, setInitialFirstName] = useState(givenName);
    const [initialLastName, setInitialLastName] = useState(familyName);

    const [isSaving, setIsSaving] = useState(false);



    // Handle file upload
    const handleUpload = (file) => {
        const isImage = file.type.startsWith('image/');
        if (!isImage) {
            message.error('You can only upload image files!');
            return false;
        }

        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            message.error('Image must be smaller than 2MB!');
            return false;
        }

        // Save current image before uploading a new one
        setPreviousImage(croppedImage);

        setImage(file);
        setModalVisible(true);
        return false;
    };




    const handleSaveChanges = async () => {
        try {
            setIsSaving(true);

            // Получение текущих значений формы
            const values = form.getFieldsValue();

            // Определение изменённых полей
            const updatedData = {};

            if (values.firstName !== initialFirstName) {
                updatedData.firstName = values.firstName;
            }

            if (values.lastName !== initialLastName) {
                updatedData.lastName = values.lastName;
            }

            // Проверка, было ли изменено изображение
            if (croppedImage && !croppedImage.startsWith('blob:')) {
                updatedData.avatar = base64ToFile(croppedImage,'ava.png');
            }

            console.log('Updated Data:', updatedData);

            // Если нет изменений, ничего не делаем
            if (Object.keys(updatedData).length === 0) {
                message.info('No changes to save.');
                setIsSaving(false);
                return;
            }

            // Вызов API для обновления профиля
            await profileClient.updateProfile(updatedData);





            // Обновление начальных значений в Redux
            if (updatedData.firstName || updatedData.lastName) {
                dispatch(setUserData({
                    familyName: updatedData.lastName || familyName,
                    givenName: updatedData.firstName || givenName,
                    email: email, // Email не изменяется
                }));
            }

            if (updatedData.avatar) {
                // Создаем URL для отображения нового аватара
                const newAvatarUrl = URL.createObjectURL(updatedData.avatar);
                dispatch(setUserAvatar(newAvatarUrl));

                // Сохраняем новый аватар в localStorage
                localStorage.setItem('profileImage', newAvatarUrl);
            }










            // Обновление начальных значений
            if (updatedData.firstName !== undefined) {
                setInitialFirstName(updatedData.firstName);
            }
            if (updatedData.lastName !== undefined) {
                setInitialLastName(updatedData.lastName);
            }
            if (updatedData.avatar) {
                setPreviousImage(croppedImage);
                setCroppedImage(URL.createObjectURL(updatedData.avatar)); // Обновляем отображение аватара
                localStorage.setItem('profileImage', URL.createObjectURL(updatedData.avatar));
            }

            setIsChanged(false);
            message.success('Profile updated successfully!');
        } catch (error) {
            console.error('Error updating profile:', error);
            message.error('Failed to update profile. Please try again.');
        } finally {
            setIsSaving(false);
        }
    };









    // Handle canceling the changes
    const handleCancel = () => {
        // Reset form fields to initial values
        form.setFieldsValue({
            firstName: initialFirstName,
            lastName: initialLastName,
        });

        // Reset the image to previous image
        setCroppedImage(previousImage);

        setIsChanged(false); // Reset change tracking
        message.info('Changes have been canceled.');
    };

    return (
        <div className="profile-page" style={{ padding: '20px', maxWidth: '800px', margin: '0 auto' }}>
            {/* Profile Info Section */}
            <Card
                title={
                    <Space>
                        <UserOutlined />
                        <span>Profile Info</span>
                    </Space>
                }
                bordered={false}
                style={{ marginBottom: '20px' }}
            >
                <Form
                    form={form}
                    layout="vertical"
                    initialValues={{
                        firstName: initialFirstName,
                        lastName: initialLastName,
                        email: email,
                    }}
                    onValuesChange={() => setIsChanged(true)} // Track changes in form fields
                >
                    <Form.Item>
                        <Avatar size={80} src={croppedImage} icon={!croppedImage && <UserOutlined />} />
                    </Form.Item>

                    <Form.Item>
                        <Upload accept="image/*" name="avatar" showUploadList={false} beforeUpload={handleUpload}>
                            <Button icon={<CameraOutlined />}>Change</Button>
                        </Upload>
                    </Form.Item>

                    <Form.Item label="First Name" name="firstName">
                        <Input placeholder="Enter first name" />
                    </Form.Item>

                    <Form.Item label="Last Name" name="lastName">
                        <Input placeholder="Enter last name" />
                    </Form.Item>

                    <Form.Item label="Email" name="email">
                        <Input type="email" placeholder="Enter email" disabled />
                    </Form.Item>

                    {/* Save Changes and Cancel buttons */}
                    <Form.Item>
                        <Space>
                            <Button
                                type="primary"
                                icon={<EditOutlined />}
                                disabled={!isChanged}

                                loading={isSaving}
                                onClick={handleSaveChanges}

                                // onClick={() => {
                                //     // Get current form values
                                //     const values = form.getFieldsValue();
                                //
                                //     // Update initial values
                                //     setInitialFirstName(values.firstName);
                                //     setInitialLastName(values.lastName);
                                //     setPreviousImage(croppedImage);
                                //
                                //     message.success('Changes saved!');
                                //     setIsChanged(false); // Disable the button and remove the Cancel button
                                // }}
                            >
                                Save Changes
                            </Button>
                            {isChanged && (
                                <Button type="default" icon={<CloseOutlined />} onClick={handleCancel}>
                                    Cancel
                                </Button>
                            )}
                        </Space>
                    </Form.Item>
                </Form>
            </Card>

            {/* Change Password Section */}
            <Card
                title={
                    <Space>
                        <LockOutlined />
                        <span>Change Password</span>
                    </Space>
                }
                bordered={false}
                style={{ marginBottom: '20px' }}
            >
                <Paragraph>You can change your password by initiating a reset via email.</Paragraph>
                <Button icon={<LockOutlined />}>Reset Password</Button>
            </Card>

            {/* Delete Account Section */}
            <Card
                title={
                    <Space>
                        <DeleteOutlined />
                        <span>Delete Account</span>
                    </Space>
                }
                bordered={false}
                style={{ marginBottom: '20px' }}
            >
                <Typography.Paragraph>
                    Deleting your account is irreversible. All your data will be lost.
                </Typography.Paragraph>
                <Button type="primary" danger icon={<DeleteOutlined />}>
                    Delete Account
                </Button>
            </Card>

            {/* Include the ImageCropModal */}
            <ImageCropModal
                visible={modalVisible}
                image={image}
                onCancel={() => setModalVisible(false)}
                onSave={() => setModalVisible(false)}
                previousImage={previousImage}
                setCroppedImage={setCroppedImage}
                setIsChanged={setIsChanged} // Update the isChanged state when image is saved
            />
        </div>
    );
};

export default ProfilePage;
