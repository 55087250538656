import customFetch from "../utils/fetchUtils";

const dictionaryClient = {
    getWord: async (word, locale, translateLocale) => {
        // Создаём тело запроса в формате объекта WordRequestDTO
        const requestBody = {
            word: word,
            locale: locale,
            translateLocale: translateLocale,
        };

        // Отправляем POST запрос с телом
        const response = await customFetch(`/api/dictionary/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(requestBody)
        });
        return response;
    },
};

export default dictionaryClient;
