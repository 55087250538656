export const spanishPartsOfSpeech = [
    { value: 'noun', label: 'Sustantivo' },
    { value: 'verb', label: 'Verbo' },
    { value: 'adjective', label: 'Adjetivo' },
    { value: 'adverb', label: 'Adverbio' },
    { value: 'pronoun', label: 'Pronombre' },
    { value: 'preposition', label: 'Preposición' },
    { value: 'conjunction', label: 'Conjunción' },
    { value: 'interjection', label: 'Interjección' },
    { value: 'article', label: 'Artículo' },             // Article
    { value: 'numeral', label: 'Numeral' },              // Numeral
    { value: 'particle', label: 'Partícula' },           // Particle
    { value: 'auxiliary', label: 'Verbo auxiliar' },     // Auxiliary Verb
];
