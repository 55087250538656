import React, { useState, useEffect } from 'react';
import { message, Spin, Grid } from 'antd';
import { useSelector } from 'react-redux';
import presetClient from "../../client/PresetClient";
import PresetListDesktop from "../../components/PresetList/PresetListDesktop";
import PresetListMobile from "../../components/PresetList/PresetListMobile";

const { useBreakpoint } = Grid;

const PresetPage = () => {
    const [presets, setPresets] = useState([]);
    const [loading, setLoading] = useState(false);
    const screens = useBreakpoint();

    // Redux hook to access selected deck from state
    const selectedDeck = useSelector((state) => state.deck.selectedDeck);

    // Extract deckId from selectedDeck if available
    const deckId = selectedDeck ? selectedDeck.deckId : null;

    useEffect(() => {
        console.log('Selected Deck:', selectedDeck);
        if (selectedDeck && deckId) {
            // Предположим, что `locale` и `translateLocale` имеют строковые значения `code`
            const localeCode = selectedDeck.locale?.code || selectedDeck.locale;
            const translateLocaleCode = selectedDeck.translateLocale?.code || selectedDeck.translateLocale;

            fetchPresets(selectedDeck.type, deckId, localeCode, translateLocaleCode);
        }
    }, [selectedDeck, deckId]);


    const fetchPresets = (deckType, deckId, locale, translateLocale) => {
        setLoading(true);
        presetClient.getPresetsByDeckType(deckType, deckId, locale, translateLocale)
            .then(response => {
                setPresets(response);
                setLoading(false);
            })
            .catch(error => {
                console.error('Error fetching presets:', error);
                message.error('Failed to load presets.');
                setLoading(false);
            });
    };



    const togglePresetUsage = (deckId, deckType, presetId, isUse) => {
        // Доступ к locale и translateLocale напрямую из selectedDeck
        const locale = selectedDeck.locale;
        const translateLocale = selectedDeck.translateLocale;

        const action = isUse
            ? (id, type, preset) => presetClient.removePresetFromClient(id, type, preset, locale, translateLocale)
            : (id, type, preset) => presetClient.addPresetToClient(id, type, preset, locale, translateLocale);

        action(deckId, deckType, presetId)
            .then(() => {
                setPresets(prevPresets =>
                    prevPresets.map(preset =>
                        preset.id === presetId ? { ...preset, use: !isUse } : preset
                    )
                );
            })
            .catch(error => {
                console.error(`Error ${isUse ? 'removing' : 'adding'} preset:`, error);
            });
    };



    const handleToggleMessage = (action, presetName) => {
        message.info(`${action} button clicked for ${presetName}`);
    };

    const isMobile = !screens.md;

    if (!selectedDeck) {
        return <Spin tip="Loading selected deck..." />;
    }

    return (
        <div style={{ padding: '16px' }}>
            {isMobile ? (
                <PresetListMobile
                    presets={presets}
                    loading={loading}
                    onToggleMessage={handleToggleMessage}
                    onToggleUsage={togglePresetUsage}
                    deckId={deckId}
                    deckType={selectedDeck.type}
                />
            ) : (
                <PresetListDesktop
                    presets={presets}
                    loading={loading}
                    onToggleMessage={handleToggleMessage}
                    onToggleUsage={togglePresetUsage}
                    deckId={deckId}
                    deckType={selectedDeck.type}
                />
            )}
        </div>
    );
};

export default PresetPage;
