// Drawer.jsx
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { Menu } from 'antd';
import {
    BarChartOutlined,
    SettingOutlined,
    PlusSquareOutlined,
    BulbOutlined,
    ReadOutlined,
    UnorderedListOutlined,
    AppstoreOutlined
} from '@ant-design/icons';
import { toggleDrawer } from "../../redux/actions/drawerActions";

// Функция для добавления стиля к иконкам
const withIconSize = (IconComponent, size = '20px') => (
    <IconComponent style={{ fontSize: size }} />
);

const pages = [
    { label: 'deck', icon: withIconSize(AppstoreOutlined) },
    { label: 'content', icon: withIconSize(PlusSquareOutlined) },
    { label: 'list', icon: withIconSize(UnorderedListOutlined) },
    { label: 'learn', icon: withIconSize(ReadOutlined) },
    { label: 'stat', icon: withIconSize(BarChartOutlined) },
    { label: 'set', icon: withIconSize(SettingOutlined) },
    { label: 'preset', icon: withIconSize(BulbOutlined) },
];

export default function Drawer() {
    const dispatch = useDispatch();
    const location = useLocation();
    const [selectedKey, setSelectedKey] = useState(location.pathname);

    const handleMenuClick = ({ key }) => {
        setSelectedKey(key);

        if (window.innerWidth <= 768) {
            dispatch(toggleDrawer());
        }
    };

    return (
        <div className="drawer">
            <div className="drawerWrapper">
                <div className="drawerMenu">
                    <Menu
                        mode="inline"
                        selectedKeys={[selectedKey]}
                        onClick={handleMenuClick}
                    >
                        {pages.map((page) => (
                            <Menu.Item key={`/${page.label}`} icon={page.icon}>
                                <Link to={`/${page.label}`}>{page.label}</Link>
                            </Menu.Item>
                        ))}
                    </Menu>
                </div>
            </div>
        </div>
    );
}
