import { SET_DECK } from '../actions/deckActions';

const initialState = {
    selectedDeck: null, // Храним весь объект deck
};

const deckReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_DECK:
            return {
                ...state,
                selectedDeck: action.payload, // Сохраняем весь объект deck
            };
        default:
            return state;
    }
};

export default deckReducer;
