// components/UI/Modals/ImageCropModal.jsx

import React, { useRef, useState } from 'react';
import { Modal, Slider, message } from 'antd';
import AvatarEditor from 'react-avatar-editor';

const ImageCropModal = ({
                            visible,
                            image,
                            onCancel,
                            onSave,
                            previousImage,
                            setCroppedImage,
                            setIsChanged,
                        }) => {
    const [scale, setScale] = useState(1);
    const [position, setPosition] = useState({ x: 0.5, y: 0.5 });
    const [loading, setLoading] = useState(false);
    const editorRef = useRef(null);

    const handleSave = () => {
        if (editorRef.current) {
            setLoading(true);
            const canvas = editorRef.current.getImageScaledToCanvas().toDataURL();
            setCroppedImage(canvas);
            localStorage.setItem('profileImage', canvas);
            setLoading(false);
            setIsChanged(true);
            message.success('Image successfully updated!');
            onSave(); // Close the modal
        }
    };

    const handleScaleChange = value => {
        setScale(value);
    };

    const handlePositionChange = position => {
        setPosition(position);
    };

    return (
        <Modal
            visible={visible}
            title="Crop Image"
            onCancel={onCancel}
            onOk={handleSave}
            okText="Save"
            cancelText="Cancel"
            width={400} // Increased width for better spacing
            confirmLoading={loading}
        >
            {image && (
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center', // Centers items horizontally
                    }}
                >
                    <AvatarEditor
                        ref={editorRef}
                        image={image}
                        width={250}
                        height={250}
                        border={10}
                        borderRadius={125}
                        color={[255, 255, 255, 0.9]} // RGBA
                        scale={scale}
                        position={position}
                        onPositionChange={handlePositionChange}
                    />
                    <div style={{ marginTop: '20px', width: '100%', textAlign: 'center' }}>
                        <span>Zoom: </span>
                        <Slider
                            min={1}
                            max={3}
                            step={0.1}
                            value={scale}
                            onChange={handleScaleChange}
                            style={{ width: '80%', margin: '0 auto' }} // Centers the slider
                        />
                    </div>
                </div>
            )}
        </Modal>
    );
};

export default ImageCropModal;
