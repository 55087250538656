import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Button, ConfigProvider, Table } from 'antd';
import { getAccessToken } from "../../utils/authUtils";
import './DashboardPage.css'; // Импортируем файл стилей

// Основные цвета
const primaryColor = '#00796B'; // Teal Dark
const primaryLightColor = '#B2DFDB'; // Teal 100
const dangerColor = '#D32F2F'; // Red Dark

const colorDescriptions = [
    {
        key: '1',
        name: 'Основной цвет (Teal Dark)',
        color: '#00796B',
        usage: 'Используется для основного цвета приложения, акцентных элементов и выделения активных элементов.',
    },
    {
        key: '2',
        name: 'Цвет опасности (Red Dark)',
        color: '#D32F2F',
        usage: 'Используется для обозначения опасных действий, ошибок и предупреждений.',
    },
];

const columns = [
    {
        title: 'Цвет',
        dataIndex: 'color',
        key: 'color',
        render: (text, record) => (
            <div
                style={{
                    backgroundColor: record.color,
                    width: '50px',
                    height: '50px',
                    border: '1px solid #ccc',
                }}
            />
        ),
    },
    {
        title: 'Название',
        dataIndex: 'name',
        key: 'name',
    },
    {
        title: 'Использование',
        dataIndex: 'usage',
        key: 'usage',
    },
];

const DashboardPage = () => {
    const dispatch = useDispatch();
    const [authToken, setAuthToken] = useState('');
    const [selectedRowIndex, setSelectedRowIndex] = useState(null);

    useEffect(() => {
        getAccessToken(dispatch)
            .then(token => {
                setAuthToken(token);
            })
            .catch(err => {
                console.error('Ошибка при получении access token:', err);
            });
    }, [dispatch]);

    // Данные для примера таблицы
    const tableData = [
        { key: '1', name: 'Иван Иванов', age: 32, address: 'Москва' },
        { key: '2', name: 'Ольга Петрова', age: 28, address: 'Санкт-Петербург' },
        { key: '3', name: 'Алексей Сидоров', age: 45, address: 'Новосибирск' },
    ];

    // Колонки для таблицы
    const tableColumns = [
        { title: 'Имя', dataIndex: 'name', key: 'name' },
        { title: 'Возраст', dataIndex: 'age', key: 'age' },
        { title: 'Адрес', dataIndex: 'address', key: 'address' },
    ];

    return (
        <ConfigProvider
            theme={{
                token: {
                    colorPrimary: primaryColor,
                    colorError: dangerColor,
                },
            }}
        >
            <div style={{ padding: '20px' }}>
                <h1>Dashboard Page</h1>
                {authToken && <p>Access Token: {authToken}</p>}

                {/* Таблица с используемыми цветами */}
                <div style={{ marginTop: '20px' }}>
                    <Table
                        columns={columns}
                        dataSource={colorDescriptions}
                        pagination={false}
                    />
                </div>

                {/* Примеры кнопок */}
                <div style={{ marginTop: '40px' }}>
                    <h2>Примеры кнопок</h2>
                    <div style={{ display: 'flex', gap: '10px', flexWrap: 'wrap' }}>
                        <Button type="primary">Primary Button</Button>
                        <Button>Default Button</Button>
                        <Button type="dashed">Dashed Button</Button>
                        <Button type="text">Text Button</Button>
                        <Button type="link">Link Button</Button>
                        <Button type="primary" danger>
                            Danger Button
                        </Button>




                        <Button
                            type="default"
                            danger
                            style={{ borderColor: 'blue' }}
                        >
                            Danger 2
                        </Button>








                    </div>
                </div>

                {/* Пример таблицы */}
                <div style={{ marginTop: '40px' }}>
                    <h2>Пример таблицы</h2>
                    <Table
                        dataSource={tableData}
                        columns={tableColumns}
                        pagination={false}
                        rowClassName={(record, index) => {
                            return index === selectedRowIndex ? 'selected-row' : '';
                        }}
                        onRow={(record, index) => ({
                            onClick: () => setSelectedRowIndex(index),
                        })}
                    />
                </div>
            </div>
        </ConfigProvider>
    );
};

export default DashboardPage;
