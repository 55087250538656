import customFetch from "../utils/fetchUtils";

const deckClient = {
    getAllDecks: async () => {
        return await customFetch(`/api/decks`, {
            method: 'GET',
        });
    },

    getDeckById: async (deckId) => {
        return await customFetch(`/api/decks/${deckId}`, {
            method: 'GET',
        });
    },

    createDeck: async (deck) => {
        return await customFetch(`/api/decks`, {
            method: 'POST',
            body: JSON.stringify(deck),
        });
    },

    updateDeck: async (deckId, deck) => {
        return await customFetch(`/api/decks/${deckId}`, {
            method: 'PUT',
            body: JSON.stringify(deck),
        });
    },

    deleteDeck: async (deckId) => {
        return await customFetch(`/api/decks/${deckId}`, {
            method: 'DELETE',
        });
    },
};

export default deckClient;
