// src/components/Card/CardInfoCard.jsx
import React, { useState } from 'react';
import './CardInfoCard.css';
import { Card, Typography, List, Divider, Button, Modal, message } from 'antd';
import { EditOutlined } from "@ant-design/icons";
import RichTextEditor from "../RichTextEditor/RichTextEditor";

const { Text } = Typography;

const CardInfoCard = ({ card, onUpdateCard }) => {
    const [isModalVisible, setIsModalVisible] = useState(false);

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const handleSave = (updatedCard) => {
        console.log('Сохраненные данные карточки:', updatedCard);
        if (onUpdateCard) {
            onUpdateCard(updatedCard);
        } else {
            message.success('Card saved successfully!');
        }
        setIsModalVisible(false);
    };

    const handleDelete = () => {
        // Логика удаления карточки
        message.success('Card deleted successfully!');
    };

    return (
        <>
            <Card
                key={card.id}
                className="cardInfoCard"
                bordered={false}
                styles={{ body: { padding: 0 } }}
            >
                <div style={{ padding: '16px 16px 0 16px' }}>
                    {/* Вопрос */}



                    <RichTextEditor readOnly={true} initialContent={card.question} />

                    <Divider style={{ margin: '2px 0' }} />
                    <RichTextEditor readOnly={true} textColor="#6E6E6E" initialContent={card.answer}/>





                    {/* Горизонтальный разделитель */}
                    <Divider style={{ margin: '2px 0' }} />

                    {/* Контейнер с тремя равными блоками */}
                    <div style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        paddingTop: '6px',
                        paddingBottom: '6px',
                        fontSize: '16px',
                    }}>
                        {/* Первый блок: Correct */}
                        <div style={{ flex: 1, textAlign: 'center', padding: '0 8px' }}>
                            <Text type="secondary">Correct: {card.correctAnswerCount}</Text>
                        </div>

                        {/* Вертикальный разделитель */}
                        <div style={{
                            width: '1px',
                            height: '20px',
                            backgroundColor: '#e8e8e8',
                        }} />

                        {/* Второй блок: Incorrect */}
                        <div style={{ flex: 1, textAlign: 'center', padding: '0 8px' }}>
                            <Text type="secondary">Incorrect: {card.incorrectAnswerCount}</Text>
                        </div>

                        {/* Вертикальный разделитель */}
                        <div style={{
                            width: '1px',
                            height: '20px',
                            backgroundColor: '#e8e8e8',
                        }} />

                        {/* Третий блок: Кнопка Edit */}
                        <div style={{ flex: 1, textAlign: 'center', padding: '0 8px' }}>
                            <Button
                                type="link"
                                icon={<EditOutlined />}
                                onClick={(e) => {
                                    e.stopPropagation(); // Предотвращаем всплытие события
                                    showModal();
                                }}
                            >
                                Edit
                            </Button>
                        </div>
                    </div>
                </div>
            </Card>

            {/*/!* Модальное окно для редактирования карточки *!/*/}
            {/*<Modal*/}
            {/*    title={`Edit Card: ${card.id}`}*/}
            {/*    visible={isModalVisible}*/}
            {/*    onCancel={handleCancel}*/}
            {/*    footer={null} // Используем кнопки внутри формы*/}
            {/*    width="60%"*/}
            {/*    style={{ top: 16 }}*/}
            {/*>*/}
            {/*    <EditCardForm*/}
            {/*        card={card}*/}
            {/*        deckId={card.deckId}*/}
            {/*        onSave={handleSave}*/}
            {/*        onCancel={handleCancel}*/}
            {/*        onDelete={handleDelete}*/}
            {/*    />*/}
            {/*</Modal>*/}
        </>
    );
};

export default CardInfoCard;
