export const germanPartsOfSpeech = [
    { value: 'noun', label: 'Nomen' },
    { value: 'verb', label: 'Verb' },
    { value: 'adjective', label: 'Adjektiv' },
    { value: 'adverb', label: 'Adverb' },
    { value: 'pronoun', label: 'Pronomen' },
    { value: 'preposition', label: 'Präposition' },
    { value: 'conjunction', label: 'Konjunktion' },
    { value: 'interjection', label: 'Interjektion' },
    { value: 'article', label: 'Artikel' },           // Article
    { value: 'particle', label: 'Partikel' },         // Particle
    { value: 'numeral', label: 'Zahlwort' },          // Numeral
    { value: 'auxiliary', label: 'Hilfsverb' },       // Auxiliary Verb
    { value: 'modal', label: 'Modalverb' },           // Modal Verb
];
