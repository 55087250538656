import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import wordClient from '../../client/WordClient';
import { Pagination, Spin, message, Row } from 'antd';
import WordInfoCard from "../WordInfoCard/WordInfoCard";

const WordList = () => {
    // Get the selected deck from Redux
    const selectedDeck = useSelector((state) => state.deck.selectedDeck);
    const { locale } = useSelector((state) => state.deck.selectedDeck);

    // Extract the deckId if the deck is selected
    const deckId = selectedDeck ? selectedDeck.deckId : null;

    // Component state
    const [words, setWords] = useState([]);
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalWords, setTotalWords] = useState(0);
    const pageSize = 10; // Number of words per page

    useEffect(() => {
        setCurrentPage(1); // Reset page to 1 when deckId changes
        if (deckId) {
            fetchWords(deckId, 1, pageSize);
        } else {
            setWords([]);
            setTotalWords(0); // Clear the words if no deck is selected
        }
    }, [deckId]);

    useEffect(() => {
        if (deckId) {
            fetchWords(deckId, currentPage, pageSize);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage]);

    const fetchWords = async (deckId, page, size) => {
        setLoading(true);
        try {
            const response = await wordClient.getWordsByDeckId(deckId, page - 1, size);
            setWords(response.content);
            setTotalWords(response.totalElements);
        } catch (error) {
            console.error('Error fetching words:', error);
            message.error('Failed to load words.');
        } finally {
            setLoading(false);
        }
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    // Handling case when no deck is selected
    if (!selectedDeck) {
        return (
            <div style={{ textAlign: 'center', marginTop: '50px' }}>
                <p>Please select a deck to view words.</p>
            </div>
        );
    }

    return (
        <div style={{ width: '100%', padding: '0px' }}>
            {loading ? (
                <div style={{ textAlign: 'center', marginTop: '50px' }}>
                    <Spin tip="Loading words..." size="large" />
                </div>
            ) : (
                <>
                    {words && words.length > 0 ? (
                        words.map((word) => (
                            <WordInfoCard
                                key={word.id}
                                word={word}
                                locale={locale}
                            />
                        ))
                    ) : (
                        <div style={{ textAlign: 'center', marginTop: '50px' }}>
                            <p>No words available for this deck.</p>
                        </div>
                    )}
                    {totalWords > pageSize && (
                        <Row justify="center" style={{ marginTop: '20px' }}>
                            <Pagination
                                current={currentPage}
                                pageSize={pageSize}
                                total={totalWords}
                                onChange={handlePageChange}
                            />
                        </Row>
                    )}
                </>
            )}
        </div>
    );
};

export default WordList;
