import React, { useEffect } from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import { useDispatch, useSelector } from 'react-redux';
import { toggleDrawer } from "../../redux/actions/drawerActions";
import UserProfile from "../UserProfile/UserProfile";
import { toggleUserProfile, setUserAvatar } from '../../redux/actions/profileActions'; // Добавляем setUserAvatar
import { Avatar, Typography } from 'antd';
import { UserOutlined } from "@ant-design/icons";
import profileClient from "../../client/ProfileClient"; // Импортируем profileClient для получения аватара
import './Topbar.css'; // Импортируем стили

const { Text } = Typography;

export default function Topbar() {

    const isMobile = window.innerWidth <= 768; // Например, ширина экрана до 768 пикселей считается мобильной

    const dispatch = useDispatch();

    // Получаем состояние профиля из Redux
    const isProfileOpen = useSelector(state => state.profile.isProfileOpen);

    // Получаем URL аватара пользователя из Redux
    const avatarUrl = useSelector(state => state.profile.avatar);

    // Получаем полный объект selectedDeck из Redux
    const selectedDeck = useSelector(state => state.deck.selectedDeck);

    // Извлекаем title из selectedDeck, если он существует
    const deckTitle = selectedDeck ? selectedDeck.title : null;

    // Функция для переключения состояния Drawer
    const handleToggleDrawer = () => {
        dispatch(toggleDrawer());
    };

    // Функция для переключения видимости профиля пользователя
    const handleToggleProfile = () => {
        dispatch(toggleUserProfile());
    };

    // Определяем, какой аватар использовать
    // Если avatarUrl существует и не пустой, используем его, иначе дефолтный аватар
    const avatarSrc = avatarUrl ? avatarUrl : '/assets/default-avatar.png'; // Путь к дефолтному аватару

    // Добавляем useEffect для получения аватара при монтировании компонента
    useEffect(() => {
        const fetchAvatar = async () => {
            try {
                const avatarUrl = await profileClient.getAvatar();
                dispatch(setUserAvatar(avatarUrl)); // Диспатчим аватар в Redux
            } catch (error) {
                dispatch(setUserAvatar(null)); // Устанавливаем null, если ошибка
            }
        };

        fetchAvatar(); // Вызываем функцию получения аватара
    }, [dispatch]); // Зависимость от dispatch

    return (
        <div className="topbar">
            <div className="topbarWrapper">
                <div className="topleft logoSection" onClick={handleToggleDrawer}>
                    <MenuIcon className="menuIcon" />
                    <span className="logoText">RETRIEVE</span>
                    {deckTitle && (
                        <>
                            <div className="separator"></div> {/* Separator */}
                            <Text className="deckTitle">{deckTitle}</Text>
                        </>
                    )}
                </div>
                <div className="topRight">
                    <Avatar
                        src={avatarSrc}
                        className="topAvatar"
                        onClick={handleToggleProfile} // Переключение профиля по клику
                        icon={!avatarUrl && <UserOutlined />} // Показывать иконку только если нет avatarUrl
                    />
                    {isProfileOpen && <UserProfile />} {/* Условный рендеринг компонента профиля */}
                </div>
            </div>
        </div>
    );
}
