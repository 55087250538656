import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { CognitoUser } from "amazon-cognito-identity-js";
import { TextField, Button, Typography, Box } from '@mui/material';
import { toast, ToastContainer } from "react-toastify";
import UserPool from "../../utils/userPool";
import 'react-toastify/dist/ReactToastify.css';


const ConfirmEmailPage = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [email, setEmail] = useState('');
    const [code, setCode] = useState('');

    useEffect(() => {
        if (location.state?.email) {
            setEmail(location.state.email);
        }
    }, [location.state]);

    const handleSubmit = (event) => {
        event.preventDefault();

        const user = new CognitoUser({
            Username: email,
            Pool: UserPool,
        });

        user.confirmRegistration(code, true, (err, result) => {
            if (err) {
                toast.error(`Failed to confirm registration: ${err.message}`);
                return;
            }
            toast.success("Email confirmed successfully. Please login.");
            navigate("/login");
        });
    };

    const handleResendCode = () => {
        const user = new CognitoUser({
            Username: email,
            Pool: UserPool,
        });

        user.resendConfirmationCode((err, result) => {
            if (err) {
                toast.error(`Failed to resend confirmation code: ${err.message}`);
                return;
            }
            toast.success("Confirmation code resent successfully.");
        });
    };

    return (
        <Box sx={{ maxWidth: '400px', width: '80%', margin: 'auto', padding: '2rem' }}>
            <ToastContainer />
            <Typography variant="h5" gutterBottom textAlign="center">
                Confirm Your Email
            </Typography>
            <form onSubmit={handleSubmit} noValidate>
                <TextField
                    label="Email Address"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    required
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
                <TextField
                    label="Confirmation Code"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    required
                    value={code}
                    onChange={(e) => setCode(e.target.value)}
                />
                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                    sx={{ mt: 2 }}
                >
                    Confirm Email
                </Button>
                <Button
                    variant="text"
                    color="primary"
                    fullWidth
                    sx={{ mt: 2 }}
                    onClick={handleResendCode}
                    disabled={!email}
                >
                    Resend Code
                </Button>
            </form>
        </Box>
    );
};

export default ConfirmEmailPage;
