// CardLearnPage.js
import React, { useState, useEffect } from 'react';
import { Card, Button, Typography, Row, Col, Tooltip, Space, message, Progress } from 'antd';
import { CloudSyncOutlined, SettingOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import practiceClient from "../../client/PracticeClient";
import { useSelector, useDispatch } from "react-redux";
import { setPractice } from '../../redux/actions/practiceActions';

const { Title, Paragraph, Text } = Typography;

const CardLearnPage = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const selectedDeck = useSelector((state) => state.deck.selectedDeck);

    const [practiceData, setPracticeData] = useState({
        deckId: '',
        practiceId: '',
        boxSize: {},
    });

    const [isPracticeSettingsActive, setIsPracticeSettingsActive] = useState(false);
    const [initialPracticeData, setInitialPracticeData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (selectedDeck && selectedDeck.deckId) {
            handleInitializePractice();
        }
    }, [selectedDeck]);

    const handleInitializePractice = async () => {
        if (!selectedDeck || !selectedDeck.deckId) {
            message.error('No deck selected.');
            return;
        }

        setIsLoading(true);
        const practiceRequest = {
            deckId: selectedDeck.deckId,
            practiceType: 'LEARN_CARD',
        };

        try {
            const response = await practiceClient.initializePractice(practiceRequest.practiceType,practiceRequest);
            setPracticeData({
                deckId: response.deckId,
                practiceId: response.practiceId,
                boxSize: response.boxSize,
            });

            dispatch(setPractice(response.practiceId, 'LEARN_CARD'));
         //   message.success('Practice initialized successfully.');
        } catch (error) {
            console.error('Error initializing practice:', error);
            message.error('Failed to initialize practice.');
        } finally {
            setIsLoading(false);
        }
    };

    const handleTogglePracticeSettings = () => {
        if (!isPracticeSettingsActive) {
            setInitialPracticeData(practiceData);
        } else {
            if (initialPracticeData) {
                setPracticeData(initialPracticeData);
            }
        }
        setIsPracticeSettingsActive(!isPracticeSettingsActive);
    };

    const handleApplySettings = () => {
        setIsPracticeSettingsActive(false);
    };

    const handleCancelPracticeSettings = () => {
        if (initialPracticeData) {
            setPracticeData(initialPracticeData);
            setIsPracticeSettingsActive(false);
        }
    };

    const handleRefreshPracticeSettings = () => {
        if (initialPracticeData) {
            setPracticeData(initialPracticeData);
        }
    };

    const totalCards = Object.values(practiceData.boxSize).reduce((a, b) => a + b, 0);

    return (
        <div style={{ padding: '10px', display: 'flex', justifyContent: 'center' }}>
            <Row gutter={[30, 30]} justify="center" align="top" style={{ width: '100%', display: 'flex', alignItems: 'stretch' }}>
                <Col xs={24} sm={12} md={8}>
                    <Card
                        bordered
                        style={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            height: '100%',
                            position: 'relative'
                        }}
                    >
                        <div style={{ position: 'absolute', top: 10, right: 10, display: 'flex', alignItems: 'center' }}>
                            <Tooltip title="Refresh Settings" placement="topRight">
                                <CloudSyncOutlined
                                    style={{ marginRight: '8px', fontSize: '20px', cursor: 'pointer', color: '#808080' }}
                                    onClick={handleRefreshPracticeSettings}
                                />
                            </Tooltip>
                            <Tooltip title="Setup Practice" placement="topRight">
                                <SettingOutlined
                                    style={{ fontSize: '20px', cursor: 'pointer', color: '#808080' }}
                                    onClick={handleTogglePracticeSettings}
                                />
                            </Tooltip>
                        </div>

                        <div style={{ flex: '1 0 auto', paddingTop: '0px' }}>
                            <Title level={3}>Practice</Title>
                            <Paragraph>
                                Your flashcard deck consists of {totalCards} cards.
                            </Paragraph>
                            <div style={{ marginTop: '20px', textAlign: 'left' }}>
                                {[0, 1, 2, 3, 4].map((box) => (
                                    <div key={box} style={{ marginBottom: '10px' }}>
                                        <Text>Box {box+1}: {practiceData.boxSize[box] || 0} cards</Text>
                                        <Progress
                                            percent={((practiceData.boxSize[box] || 0) / totalCards) * 100}
                                            showInfo={true}
                                            format={() => ``}
                                        />
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div style={{ marginTop: '20px' }}>
                            {isPracticeSettingsActive ? (
                                <Space style={{ width: '100%', justifyContent: 'center' }}>
                                    <Button type="primary" onClick={handleApplySettings}>
                                        Apply
                                    </Button>
                                    <Button onClick={handleCancelPracticeSettings}>
                                        Cancel
                                    </Button>
                                </Space>
                            ) : (
                                <Button
                                    type="primary"
                                    block
                                    onClick={() => navigate('/card-run')}
                                    loading={isLoading}
                                >
                                    Let's practice!
                                </Button>
                            )}
                        </div>
                    </Card>
                </Col>
            </Row>
        </div>
    );
};

export default CardLearnPage;
