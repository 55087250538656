import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux'; // Импортируем useSelector
import ContentAddQuiz from '../../components/ContentAdd/ContentAddQuiz';
import { Spin, message } from "antd";
import ContentAddWord from "../../components/ContentAdd/ContentAddWord";
import ContentAddCard from "../../components/ContentAdd/ContentAddCard"; // Добавляем message для уведомлений

const ContentPage = () => {
    const [loading, setLoading] = useState(false); // Изменено на false, так как нет загрузки с сервера
    const [selectedType, setSelectedType] = useState('');

    // Получаем selectedDeck из Redux
    const selectedDeck = useSelector((state) => state.deck.selectedDeck);

    // Обновляем selectedType при изменении selectedDeck
    useEffect(() => {
        if (selectedDeck) {
            if (selectedDeck.type) {
                setSelectedType(selectedDeck.type);
            } else {
                setSelectedType('');
                message.warning('Selected deck does not have a type.');
            }
        } else {
            setSelectedType('');
        }
    }, [selectedDeck]);

    const renderContentForm = () => {
        switch (selectedType) {
            case 'QUIZ':
                return <ContentAddQuiz />;
            case 'CARD':
                return <ContentAddCard />;
            case 'WORD':
                return <ContentAddWord />;
            // Будущие случаи для других типов
            default:
                return <div>Please select a valid content type</div>;
        }
    };

    return (
        <div style={{ width: '100%' }}>
            {loading ? (
                <div style={{ textAlign: 'center', marginTop: '50px', marginLeft: '10px', marginRight: '10px' }}>
                    <Spin tip="Loading decks..." size="large" />
                </div>
            ) : (
                <>
                    {/* Отображаем содержимое на основе выбранного типа */}
                    <div style={{ marginTop: '10px', width: '100%' }}>
                        {selectedDeck ? renderContentForm() : <div>Please select a deck to view content.</div>}
                    </div>
                </>
            )}
        </div>
    );
};

export default ContentPage;
