import React, { useState } from 'react';
import { TextField, Link, Typography, Box } from '@mui/material';
import { Button } from 'antd';
import { CognitoUser, AuthenticationDetails } from "amazon-cognito-identity-js";
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from "react-toastify";
import UserPool from "../../utils/userPool";
import { login } from "../../redux/actions/authActions";
import { useDispatch } from "react-redux";
import 'react-toastify/dist/ReactToastify.css';
import {jwtDecode} from "jwt-decode"; // Исправленный импорт jwtDecode
import { setUserData, setUserAvatar } from "../../redux/actions/profileActions";
import profileClient from "../../client/ProfileClient"; // Импорт экшенов для профиля

const LoginPage = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleSubmit = async (event) => {
        event.preventDefault();

        const user = new CognitoUser({
            Username: email,
            Pool: UserPool,
        });

        const authDetails = new AuthenticationDetails({
            Username: email,
            Password: password,
        });

        user.authenticateUser(authDetails, {
            onSuccess: async (data) => {
                const idToken = data.getIdToken().getJwtToken(); // Получение JWT токена из Cognito
                const decodedToken = jwtDecode(idToken);

                // Сохранение токена в localStorage (или другом месте)
                localStorage.setItem('token', idToken);

                // Диспатч данных пользователя в Redux
                dispatch(setUserData({
                    familyName: decodedToken.family_name,
                    givenName: decodedToken.given_name,
                    email: decodedToken.email
                }));

                // Диспатч действия логина
                dispatch(login());
                // Навигация на страницу dashboard
                navigate("/dashboard");
            },
            onFailure: (err) => {
                toast.error(err.message);
                if (err.code === "UserNotConfirmedException") {
                    navigate("/confirm-email", { state: { email: email } });
                }
            },
            newPasswordRequired: (data) => {
                console.log("newPasswordRequired: ", data);
            },
        });
    };

    return (
        <Box sx={{ maxWidth: '400px', width: '80%', margin: 'auto', padding: '2rem' }}>
            <ToastContainer />
            <Typography variant="h4" gutterBottom textAlign="center">
                Login
            </Typography>
            <form onSubmit={handleSubmit} noValidate>
                <TextField
                    label="Email Address"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    autoComplete="email"
                />
                <TextField
                    label="Password"
                    type="password"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    autoComplete="current-password"
                />
                <Button
                    type="primary"
                    htmlType="submit"
                    style={{
                        width: '100%',
                        marginTop: '16px',
                    }}
                >
                    Login
                </Button>
                <Box display="flex" justifyContent="space-between" mt={2}>
                    <Link href="/registration" variant="body2">
                        Don't have an account?
                    </Link>
                    <Link href="#" variant="body2">
                        Forgot Password?
                    </Link>
                </Box>
            </form>
        </Box>
    );
};

export default LoginPage;
