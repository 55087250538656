// src/components/Card/CardList.jsx
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import cardClient from '../../client/CardClient';
import { Pagination, Spin, message, Row } from 'antd';
import CardInfoCard from "../CardInfoCard/CardInfoCard";

const CardList = () => {
    // Получаем полный объект selectedDeck из Redux
    const selectedDeck = useSelector((state) => state.deck.selectedDeck);

    // Извлекаем deckId из selectedDeck, если он существует
    const deckId = selectedDeck ? selectedDeck.deckId : null;

    // Состояния компонента
    const [cards, setCards] = useState([]);
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalCards, setTotalCards] = useState(0);
    const pageSize = 10; // Количество карточек на странице

    useEffect(() => {
        // Сброс текущей страницы при изменении deckId
        setCurrentPage(1);

        if (deckId) {
            fetchCards(deckId, 1, pageSize);
        } else {
            // Если колода не выбрана, очистить карточки и общее количество
            setCards([]);
            setTotalCards(0);
        }
    }, [deckId]);

    useEffect(() => {
        if (deckId) {
            fetchCards(deckId, currentPage, pageSize);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage]);

    const fetchCards = async (deckId, page, size) => {
        setLoading(true);
        try {
            const response = await cardClient.getCardsByDeckId(deckId, page - 1, size);
            console.log('Cards fetched:', response);
            setCards(response.content);
            setTotalCards(response.totalElements);
        } catch (error) {
            console.error('Error fetching cards:', error);
            message.error('Не удалось загрузить карточки.');
        } finally {
            setLoading(false);
        }
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    // Обработка случаев, когда selectedDeck не выбран
    if (!selectedDeck) {
        return (
            <div style={{ textAlign: 'center', marginTop: '50px' }}>
                <p>Пожалуйста, выберите колоду для просмотра карточек.</p>
            </div>
        );
    }

    return (
        <div style={{ width: '100%', padding: '0px' }}>
            {loading ? (
                <div style={{ textAlign: 'center', marginTop: '50px' }}>
                    <Spin tip="Загрузка карточек..." size="large" />
                </div>
            ) : (
                <>
                    {cards && cards.length > 0 ? (
                        cards.map((card) => (
                            <CardInfoCard
                                key={card.id}
                                card={card}
                            />
                        ))
                    ) : (
                        <div style={{ textAlign: 'center', marginTop: '50px' }}>
                            <p>Карточки для этой колоды отсутствуют.</p>
                        </div>
                    )}
                    {totalCards > pageSize && (
                        <Row justify="center" style={{ marginTop: '20px' }}>
                            <Pagination
                                current={currentPage}
                                pageSize={pageSize}
                                total={totalCards}
                                onChange={handlePageChange}
                            />
                        </Row>
                    )}
                </>
            )}
        </div>
    );
};

export default CardList;
