import React from 'react';
import { List, Card, Space, Row, Col, Button, Tooltip } from 'antd';
import { CheckCircleFilled, PlusCircleOutlined, MinusCircleOutlined, InfoCircleOutlined } from '@ant-design/icons';

const PresetListDesktop = ({
                               presets,
                               loading,
                               selectedPresetId,
                               onToggleMessage,
                               onToggleUsage,
                               deckId,
                               deckType
                           }) => {
    return (
        <List
            grid={{ gutter: 16, column: 1 }}
            loading={loading}
            dataSource={presets}
            locale={{ emptyText: 'No available presets' }}
            renderItem={preset => (
                <List.Item>
                    <Card
                        bordered={selectedPresetId === preset.id}
                        style={{
                            borderColor: selectedPresetId === preset.id ? '#02796b' : undefined,
                            width: '100%',
                            paddingLeft: '0px',
                            boxShadow: '0 0px 0px rgba(0, 0, 0, 0.1)',
                            borderRadius: '6px',
                            backgroundColor: '#fff',
                        }}
                    >
                        <Row gutter={16} align="middle">
                            <Col span={12}>
                                <h3 style={{
                                    margin: '0 0 4px 0',
                                    fontSize: '16px',
                                    color: preset.use ? '#027b83' : '#000' // Зеленый цвет при use = true
                                }}>
                                    {preset.name}
                                </h3>
                                <p style={{ margin: '0', fontStyle: 'italic', fontSize: '14px', color: '#555' }}>
                                    {preset.description}
                                </p>
                            </Col>
                            <Col span={8}>
                                <p><strong>Items:</strong> {preset.itemCount}</p>
                                {preset.languageLocale && (
                                    <p><strong>Language:</strong> {preset.languageLocale}</p>
                                )}
                            </Col>




                            <Col span={4}>
                                <Space size="small" align="center" style={{ justifyContent: 'flex-end', width: '100%' }}>
                                    <Tooltip>
                                        <Button
                                            icon={preset.use ? <MinusCircleOutlined /> : <PlusCircleOutlined />}

                                            onClick={(e) => {
                                                e.stopPropagation();
                                                onToggleUsage(deckId, deckType, preset.id, preset.use);
                                            }}
                                            style={{
                                                fontSize: '16px',
                                                color: preset.use ? '#8B0000' : '#02796b' // Цвет иконки в зависимости от статуса use
                                            }}
                                        />
                                    </Tooltip>
                                    <Button
                                        icon={<InfoCircleOutlined />}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            onToggleMessage("Info", preset.name);
                                        }}
                                        style={{ fontSize: '16px', color: '#808080' }}
                                    />
                                </Space>
                            </Col>





                        </Row>
                    </Card>
                </List.Item>
            )}
        />
    );
};

export default PresetListDesktop;
