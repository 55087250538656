import React, { useState, useEffect } from 'react';
import { Card, Typography, Divider, Button, Modal, message } from 'antd';
import { EditOutlined, SoundFilled, CaretDownOutlined, CaretUpOutlined } from "@ant-design/icons";
import audioClient from "../../client/audioClient"; // Импорт клиента для получения аудио
import './WordInfoCard.css';

const { Text } = Typography;

const WordInfoCard = ({ word, onUpdateWord, locale }) => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [audioUrl, setAudioUrl] = useState(null);
    const [exampleAudioCache, setExampleAudioCache] = useState({});
    const [openExamples, setOpenExamples] = useState({}); // <--- Состояние для раскрытия примеров

    // Отображение модального окна
    const showModal = () => {
        setIsModalVisible(true);
    };

    // Закрытие модального окна
    const handleCancel = () => {
        setIsModalVisible(false);
    };

    // Сохранение изменений слова
    const handleSave = (updatedWord) => {
        if (onUpdateWord) {
            onUpdateWord(updatedWord);
        } else {
            message.success('Word saved successfully!');
        }
        setIsModalVisible(false);
    };

    // Загрузка аудио при изменении слова
    useEffect(() => {
        const fetchAudio = async () => {
            try {
                const audioBlob = await audioClient.getWordAudio({
                    word: word.headword,
                    partOfSpeech: word.partOfSpeech,
                    locale: locale.code
                });
                const audioUrl = URL.createObjectURL(audioBlob);
                setAudioUrl(audioUrl);
            } catch (error) {
                console.error("Error fetching audio:", error);
            }
        };

        fetchAudio();

        return () => {
            if (audioUrl) {
                URL.revokeObjectURL(audioUrl);
            }
        };
    }, [word]);

    // Воспроизведение аудио
    const playAudio = () => {
        playAudioByUrl(audioUrl);
    };

    const playAudioByUrl = (url) => {
        if (url) {
            const audio = new Audio(url);
            audio.play().catch((error) => {
                console.error("Ошибка при попытке воспроизведения аудио:", error);
            });
        }
    };

    const playExampleAudio = async (uuid) => {
        if (exampleAudioCache[uuid]) {
            playAudioByUrl(exampleAudioCache[uuid]);
        } else {
            try {
                const audioBlob = await audioClient.getWordAudio({
                    word: word.headword,
                    partOfSpeech: word.partOfSpeech,
                    locale: locale.code,
                    uuid,
                });
                const exampleAudioUrl = URL.createObjectURL(audioBlob);

                setExampleAudioCache((prevCache) => ({
                    ...prevCache,
                    [uuid]: exampleAudioUrl,
                }));

                playAudioByUrl(exampleAudioUrl);
            } catch (error) {
                console.error("Error fetching example audio:", error);
            }
        }
    };

    const toggleExamples = (index) => {
        setOpenExamples((prevState) => ({
            ...prevState,
            [index]: !prevState[index],
        }));
    };

    return (
        <>
            <Card
                key={word.id}
                className="wordInfoCard"
                bordered={false}
                styles={{ body: { padding: 0 } }}
            >
                <div style={{ padding: '16px 16px 0 16px' }}>
                    {/* Word Headword with Speaker Icon */}
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        {!word.phonetic && (
                            <div
                                style={{
                                    borderRadius: '25%',
                                    padding: '3px',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    cursor: 'pointer',
                                    border: '1.5px solid #838383',
                                    marginRight: '8px', // Отступ между иконкой и headword
                                }}
                                onClick={(event) => {
                                    event.stopPropagation();
                                    playAudio();
                                }}
                            >
                                <SoundFilled style={{fontSize: '0.9em', color: '#838383'}}/>
                            </div>
                        )}
                        <div style={{
                            display: 'flex',
                            alignItems: 'baseline'
                        }}>
                            <Text strong style={{fontSize: '2em', color: '#027b83'}}>{word.headword}</Text>
                            <Text strong style={{fontSize: '1.2em', marginLeft: '8px'}}>{word.partOfSpeech}</Text>
                        </div>
                    </div>

                    {/* Transcription with Sound Icon */}
                    <div style={{display: 'flex', justifyContent: 'left', alignItems: 'center', marginTop: '0px'}}>


                        {word.phonetic && (

                            <div
                                style={{
                                    borderRadius: '25%',
                                    padding: '3px',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    cursor: 'pointer',
                                    border: '1.5px solid #838383',
                                }}
                                onClick={(event) => {
                                    event.stopPropagation();
                                    playAudio();
                                }}
                            >
                                <SoundFilled style={{fontSize: '0.9em', color: '#838383'}}/>
                            </div>)}
                            <Text style={{fontSize: '1.5em', marginLeft: '8px'}}>
                                {word.phonetic ? `/${word.phonetic}/` : ''}
                            </Text>

                        </div>

                        <Divider style={{margin: '8px 0'}}/>

                        {word.wordDefinition.map((def, index) => (
                            <div key={index} style={{marginBottom: '16px'}}>
                                <div onClick={() => toggleExamples(index)}
                                     style={{cursor: 'pointer', display: 'flex', alignItems: 'center'}}>
                                    {openExamples[index] ? <CaretUpOutlined style={{color: '#838383'}}/> :
                                        <CaretDownOutlined style={{color: '#838383'}}/>}
                                    <Text strong style={{marginLeft: '8px'}}>{def.definition}</Text>
                                </div>
                                <Text italic strong
                                      style={{marginLeft: '36px', color: '#ee6002'}}>{def.translation}</Text>

                                {openExamples[index] && (
                                    <div style={{marginTop: '10px', marginLeft: '16px'}}>
                                        {def.examples.map((example, exampleIndex) => (
                                            <div key={exampleIndex} style={{marginBottom: '8px'}}>
                                                <div
                                                    style={{
                                                        border: '1px solid #838383',
                                                        borderRadius: '25%',
                                                        padding: '3px',
                                                        display: 'inline-flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        cursor: 'pointer',
                                                        marginRight: '8px',
                                                    }}
                                                    onClick={(event) => {
                                                        event.stopPropagation();
                                                        playExampleAudio(example.id);
                                                    }}
                                                >
                                                    <SoundFilled style={{fontSize: '0.6em', color: '#838383'}}/>
                                                </div>
                                                <Text>{example.sentence}</Text>
                                                <br/>
                                                <Text  type="secondary" italic style={{marginLeft: '32px'}}>{example.translation}</Text>
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                        ))}

                        <Divider style={{margin: '2px 0'}}/>

                        {/* Correct and Incorrect Counts */}
                        <div style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            paddingTop: '6px',
                            paddingBottom: '6px',
                            fontSize: '16px',
                        }}>
                            <div style={{flex: 1, textAlign: 'center', padding: '0 8px'}}>
                                <Text type="secondary">Correct: {word.correctAnswerCount || 0}</Text>
                            </div>
                            <div style={{
                                width: '1px',
                                height: '20px',
                                backgroundColor: '#e8e8e8',
                            }}/>
                            <div style={{flex: 1, textAlign: 'center', padding: '0 8px'}}>
                                <Text type="secondary">Incorrect: {word.incorrectAnswerCount || 0}</Text>
                            </div>
                            <div style={{
                                width: '1px',
                                height: '20px',
                                backgroundColor: '#e8e8e8',
                            }}/>

                            {/* Edit Button */}
                            <div style={{flex: 1, textAlign: 'center', padding: '0 8px'}}>
                                <Button
                                    type="link"
                                    icon={<EditOutlined/>}
                                    onClick={(e) => {
                                        e.stopPropagation(); // Останавливаем всплытие
                                        showModal();
                                    }}
                                >
                                    Edit
                                </Button>
                            </div>
                        </div>
                    </div>
            </Card>

            {/* Modal for Editing Word */}
            <Modal
                title={`Edit Word: ${word.headword}`}
                visible={isModalVisible}
                onCancel={handleCancel}
                footer={null}
                width="60%"
                style={{ top: 16 }}
            >
                {/* Здесь можно добавить форму для редактирования */}
                <p>Edit form placeholder</p>
                <Button onClick={() => handleSave(word)}>Save</Button>
                <Button onClick={handleCancel}>Cancel</Button>
            </Modal>
        </>
    );
};

export default WordInfoCard;
