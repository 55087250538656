import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Button,
    Modal,
    Form,
    Input,
    Select,
    Space,
    message,
    Tooltip,
    Row,
    Col,
    Grid
} from 'antd';
import {
    GlobalOutlined,
    LockOutlined,
    PlusCircleOutlined,
    UploadOutlined
} from '@ant-design/icons';
import './DeckPage.css';
import deckClient from "../../client/DeckClient";
import { setDeck } from '../../redux/actions/deckActions';
import DeckListDesktop from "../../components/DeckList/DeckListDesktop";
import DeckListMobile from "../../components/DeckList/DeckListMobile";

// Импорт изображений флагов
import englishGbFlag from '../../assets/flag/rounded/ENGLISH_GB.png';
import germanFlag from '../../assets/flag/rounded/GERMAN.png';
import spanishEsFlag from '../../assets/flag/rounded/SPANISH_ES.png';
import russianFlag from '../../assets/flag/rounded/RUSSIAN.png';
import chineseSimplifiedFlag from '../../assets/flag/rounded/CHINESE_SIMPLIFIED.png';
import italianFlag from '../../assets/flag/rounded/ITALIAN.png';
import polishFlag from '../../assets/flag/rounded/POLISH.png';
import frenchFlag from '../../assets/flag/rounded/FRENCH.png';

const { Option } = Select;
const { useBreakpoint } = Grid;

const initialDeckForm = {
    title: '',
    description: '',
    type: 'CARD',
    visibility: 'PRIVATE',
    locale: '',
    translationLanguage: ''
};

const deckTypes = ['CARD', 'QUIZ', 'WORD', 'FILL'];
const deckVisibilityOptions = ['PRIVATE', 'PUBLIC'];

// Объекты с данными о языках, включая флаги и коды
const locale = [
    { name: 'English (British)', code: 'ENGLISH_GB', flag: englishGbFlag },
    { name: 'Spanish (Spain)', code: 'SPANISH_ES', flag: spanishEsFlag },
    { name: 'German', code: 'GERMAN', flag: germanFlag },
    { name: 'French (France)', code: 'FRENCH_FR', flag: frenchFlag },
    { name: 'Polish', code: 'POLISH', flag: polishFlag },

    // { name: 'Chinese (Simplified)', code: 'CHINESE_SIMPLIFIED', flag: chineseSimplifiedFlag },
    // { name: 'Italian', code: 'ITALIAN', flag: italianFlag },
];

const translateLocale = [
    { name: 'English (British)', code: 'ENGLISH_GB', flag: englishGbFlag },
    { name: 'Spanish (Spain)', code: 'SPANISH_ES', flag: spanishEsFlag },
    { name: 'German', code: 'GERMAN', flag: germanFlag },
    { name: 'French (France)', code: 'FRENCH_FR', flag: frenchFlag },
    { name: 'Russian', code: 'RUSSIAN', flag: russianFlag },
    { name: 'Polish', code: 'POLISH', flag: polishFlag },


];

const flags = {
    ENGLISH_GB: englishGbFlag,
    GERMAN: germanFlag,
    SPANISH_ES: spanishEsFlag,
    RUSSIAN: russianFlag,
    CHINESE_SIMPLIFIED: chineseSimplifiedFlag,
    ITALIAN: italianFlag,
    POLISH: polishFlag,
};


const DeckPage = () => {
    const [decks, setDecks] = useState([]);
    const [openDialog, setOpenDialog] = useState(false);
    const [currentDeck, setCurrentDeck] = useState(initialDeckForm);
    const [isEditMode, setIsEditMode] = useState(false);
    const [selectedDeckId, setSelectedDeckId] = useState(null);
    const [loading, setLoading] = useState(true);
    const [form] = Form.useForm();

    const screens = useBreakpoint();
    const dispatch = useDispatch();
    const selectedDeck = useSelector((state) => state.deck.selectedDeck);

    useEffect(() => {
        fetchDecks();
    }, []);

    const fetchDecks = () => {
        setLoading(true);
        deckClient.getAllDecks()
            .then(response => {
                const updatedDecks = response.map(deck => {
                    // Находим флаги для locale и translateLocale по коду
                    const localeOption = locale.find(opt => opt.code === deck.locale);
                    const translateLocaleOption = translateLocale.find(opt => opt.code === deck.translateLocale);

                    return {
                        ...deck,
                        locale: localeOption ? { ...localeOption } : null,
                        translateLocale: translateLocaleOption ? { ...translateLocaleOption } : null,
                    };
                });

                console.log("Updated decks with flags:", updatedDecks);

                setDecks(updatedDecks);
                setLoading(false);
                if (selectedDeck) {
                    const selectedDeckObj = updatedDecks.find(deck => deck.deckId === selectedDeck.deckId);
                    if (selectedDeckObj) {
                        setCurrentDeck(selectedDeckObj);
                        setSelectedDeckId(selectedDeckObj.deckId);
                    }
                }
            })
            .catch(error => {
                console.error('Error fetching decks:', error);
                message.error('Failed to load decks.');
                setLoading(false);
            });
    };

    const handleChange = (changedValues) => {
        setCurrentDeck({ ...currentDeck, ...changedValues });
    };

    const handleSave = () => {
        form.validateFields().then(() => {
            const saveOrUpdate = isEditMode
                ? deckClient.updateDeck(currentDeck.deckId, currentDeck)
                : deckClient.createDeck(currentDeck);
            saveOrUpdate.then(() => {
                setOpenDialog(false);
                form.resetFields(); // Сбросить поля формы после сохранения
                setIsEditMode(false);
                fetchDecks();
                message.success(isEditMode ? 'Deck successfully updated!' : 'Deck successfully created!');
            }).catch(error => {
                console.error(error);
                message.error('Failed to save deck.');
            });
        });
    };



    const handleCreateNew = () => {
        setIsEditMode(false);
        setCurrentDeck(initialDeckForm);
        form.resetFields();
        setOpenDialog(true);
    };

    const handleEdit = (deck) => {
        setIsEditMode(true);
        setCurrentDeck(deck);
        setOpenDialog(true);
        form.setFieldsValue(deck);
    };

    const handleDelete = (deckId) => {
        Modal.confirm({
            title: 'Are you sure you want to delete this deck?',
            content: 'This action cannot be undone.',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                deckClient.deleteDeck(deckId).then(() => {
                    fetchDecks();
                    if (selectedDeck && selectedDeck.deckId === deckId) {
                        dispatch(setDeck(null));
                        setSelectedDeckId(null);
                    }
                    message.success('Deck successfully deleted!');
                }).catch(error => {
                    console.error(error);
                    message.error('Failed to delete deck.');
                });
            },
            onCancel() {
                console.log('Deletion cancelled');
            },
        });
    };

    const handleSetup = (deck) => {
        console.log('Setup clicked for deck:', deck);
    };

    const renderVisibility = (visibility) => {
        if (visibility === 'PRIVATE') {
            return (
                <Tooltip title="Private">
                    <Space>
                        <LockOutlined />
                        <span>Private</span>
                    </Space>
                </Tooltip>
            );
        } else if (visibility === 'PUBLIC') {
            return (
                <Tooltip title="Public">
                    <Space>
                        <GlobalOutlined />
                        <span>Public</span>
                    </Space>
                </Tooltip>
            );
        } else {
            return null;
        }
    };

    const handleRowClick = (deck) => {
        setSelectedDeckId(deck.deckId);
        dispatch(setDeck(deck));
    };






    const renderLanguageOptions = (languages) => {
        return languages.map(({ name, code, flag }) => (
            <Option key={code} value={code}>
                <div className="flag-container">
                    <img src={flag} alt={name} className="flag-image" />
                    <span className="language-text">{name}</span>
                </div>
            </Option>
        ));
    };




    const isMobile = !screens.md;

    return (
        <div style={{ padding: '6px' }}>
            <Row justify="end" style={{ marginBottom: 16 }}>
                <Space>
                    <Button icon={<UploadOutlined />}>Import CSV</Button>
                    <Button type="primary" icon={<PlusCircleOutlined />} onClick={handleCreateNew}>
                        Add Deck
                    </Button>
                </Space>
            </Row>
            {isMobile ? (
                <DeckListMobile
                    decks={decks}
                    loading={loading}
                    selectedDeckId={selectedDeckId}
                    onEdit={handleEdit}
                    onDelete={handleDelete}
                    onSetup={handleSetup}
                    onRowClick={handleRowClick}
                    renderVisibility={renderVisibility}
                    localeOptions={locale} // Передача массива locale
                    translateLocaleOptions={translateLocale} // Передача массива translateLocale

                />
            ) : (
                <DeckListDesktop
                    decks={decks}
                    loading={loading}
                    selectedDeckId={selectedDeckId}
                    onEdit={handleEdit}
                    onDelete={handleDelete}
                    onSetup={handleSetup}
                    onRowClick={handleRowClick}
                    renderVisibility={renderVisibility}
                    localeOptions={locale} // Передача массива locale
                    translateLocaleOptions={translateLocale} // Передача массива translateLocale

                />
            )}
            <Modal
                title={isEditMode ? 'Edit Deck' : 'Create New Deck'}
                visible={openDialog}
                onCancel={() => {
                    setOpenDialog(false);
                    form.resetFields(); // Reset form when modal is closed
                }}
                onOk={handleSave}
                okText="Save"
                cancelText="Cancel"
                centered
            >
                <Form form={form} layout="vertical" onValuesChange={handleChange} initialValues={currentDeck}>
                    <Form.Item name="title" label="Title" rules={[{ required: true }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item name="description" label="Description" rules={[{ required: true }]}>
                        <Input.TextArea rows={4} />
                    </Form.Item>
                    <Form.Item name="type" label="Type" rules={[{ required: true }]}>
                        <Select>
                            {deckTypes.map(type => (
                                <Option key={type} value={type}>{type}</Option>
                            ))}
                        </Select>
                    </Form.Item>


                            <Form.Item name="locale" label="Learning Language" rules={[{ required: true }]}>
                                <Select>{renderLanguageOptions(locale)}</Select>
                            </Form.Item>

                            <Form.Item name="translateLocale" label="Translation Language" rules={[{ required: true }]}>
                                <Select>{renderLanguageOptions(translateLocale)}</Select>
                            </Form.Item>


                    <Form.Item name="visibility" label="Visibility" rules={[{ required: true }]}>
                        <Select>
                            {deckVisibilityOptions.map(option => (
                                <Option key={option} value={option}>{option}</Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
};

export default DeckPage;
