// src/components/RichTextEditor/plugins/ToolbarPlugin.js

import React, { useCallback, useEffect, useState } from 'react';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import {
    $getSelection,
    $isRangeSelection,
    FORMAT_ELEMENT_COMMAND,
    FORMAT_TEXT_COMMAND,
    UNDO_COMMAND,
    REDO_COMMAND,
    SELECTION_CHANGE_COMMAND,
    CAN_UNDO_COMMAND,
    CAN_REDO_COMMAND,
} from 'lexical';
import { mergeRegister } from '@lexical/utils';
import {
    BoldOutlined,
    ItalicOutlined,
    UnderlineOutlined,
    StrikethroughOutlined,
    UndoOutlined,
    RedoOutlined,
    AlignLeftOutlined,
    AlignCenterOutlined,
    AlignRightOutlined,
    MenuOutlined,
    FontColorsOutlined,
    CodeOutlined,
} from '@ant-design/icons';
import { Popover, Dropdown, Menu } from 'antd';
import { SketchPicker } from 'react-color'; // Color Picker from react-color
import './ToolbarPlugin.css';
import useIsMobile from "../../../hooks/useIsMobile"; // Import styles

function Divider() {
    return <div className="divider" />;
}

export default function ToolbarPlugin() {
    const [editor] = useLexicalComposerContext();
    const [isBold, setIsBold] = useState(false);
    const [isItalic, setIsItalic] = useState(false);
    const [isUnderline, setIsUnderline] = useState(false);
    const [isStrikethrough, setIsStrikethrough] = useState(false);
    const [canUndo, setCanUndo] = useState(false);
    const [canRedo, setCanRedo] = useState(false);
    const [isCode, setIsCode] = useState(false);

    // State for managing the visibility of the Color Picker
    const [isColorPickerVisible, setIsColorPickerVisible] = useState(false);
    const [currentColor, setCurrentColor] = useState('#000000'); // Initial color

    // Use the custom hook to determine if it's a mobile device
    const isMobile = useIsMobile();

    const updateToolbar = useCallback(() => {
        editor.getEditorState().read(() => {
            const selection = $getSelection();

            if ($isRangeSelection(selection)) {
                setIsBold(selection.hasFormat('bold'));
                setIsItalic(selection.hasFormat('italic'));
                setIsUnderline(selection.hasFormat('underline'));
                setIsStrikethrough(selection.hasFormat('strikethrough'));
                setIsCode(selection.hasFormat('code'));
            }
        });
    }, [editor]);

    useEffect(() => {
        return mergeRegister(
            editor.registerUpdateListener(() => {
                updateToolbar();
            }),
            editor.registerCommand(
                SELECTION_CHANGE_COMMAND,
                () => {
                    updateToolbar();
                    return false;
                },
                1,
            ),
            editor.registerCommand(
                CAN_UNDO_COMMAND,
                (payload) => {
                    setCanUndo(payload);
                    return false;
                },
                1,
            ),
            editor.registerCommand(
                CAN_REDO_COMMAND,
                (payload) => {
                    setCanRedo(payload);
                    return false;
                },
                1,
            ),
        );
    }, [editor, updateToolbar]);

    // Function to handle color changes
    const handleColorChange = (color) => {
        setCurrentColor(color.hex);
        // Apply color to text
        editor.dispatchCommand(FORMAT_TEXT_COMMAND, { type: 'color', value: color.hex });
    };

    // Function to manage the visibility of the Color Picker
    const handleColorPickerVisibleChange = (visible) => {
        setIsColorPickerVisible(visible);
    };

    // Menu for text alignment options
    const alignMenu = (
        <Menu>
            <Menu.Item key="left" onClick={() => editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'left')}>
                <AlignLeftOutlined style={{ marginRight: '8px' }} /> Align Left
            </Menu.Item>
            <Menu.Item key="center" onClick={() => editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'center')}>
                <AlignCenterOutlined style={{ marginRight: '8px' }} /> Align Center
            </Menu.Item>
            <Menu.Item key="right" onClick={() => editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'right')}>
                <AlignRightOutlined style={{ marginRight: '8px' }} /> Align Right
            </Menu.Item>
            <Menu.Item key="justify" onClick={() => editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'justify')}>
                <MenuOutlined style={{ marginRight: '8px' }} /> Justify
            </Menu.Item>
        </Menu>
    );

    return (
        <div className="toolbar">
            <button
                disabled={!canUndo}
                onClick={() => editor.dispatchCommand(UNDO_COMMAND, undefined)}
                className="toolbar-item"
                aria-label="Undo"
            >
                <UndoOutlined />
            </button>
            <button
                disabled={!canRedo}
                onClick={() => editor.dispatchCommand(REDO_COMMAND, undefined)}
                className="toolbar-item"
                aria-label="Redo"
            >
                <RedoOutlined />
            </button>
            <Divider />
            <button
                onClick={() => editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'bold')}
                className={`toolbar-item ${isBold ? 'active' : ''}`}
                aria-label="Bold"
            >
                <BoldOutlined />
            </button>
            <button
                onClick={() => editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'italic')}
                className={`toolbar-item ${isItalic ? 'active' : ''}`}
                aria-label="Italic"
            >
                <ItalicOutlined />
            </button>
            <button
                onClick={() => editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'underline')}
                className={`toolbar-item ${isUnderline ? 'active' : ''}`}
                aria-label="Underline"
            >
                <UnderlineOutlined />
            </button>
            <button
                onClick={() => editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'strikethrough')}
                className={`toolbar-item ${isStrikethrough ? 'active' : ''}`}
                aria-label="Strikethrough"
            >
                <StrikethroughOutlined />
            </button>

            <Divider />

            <button
                onClick={() => editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'code')}
                className={`toolbar-item ${isCode ? 'active' : ''}`}
                aria-label="Code"
            >
                <CodeOutlined />
            </button>
            <Divider />

            {/* Button for Color Picker */}
            <Popover
                content={
                    <div>
                        <SketchPicker
                            color={currentColor}
                            onChange={handleColorChange}
                        />
                    </div>
                }
                trigger="click"
                visible={isColorPickerVisible}
                onVisibleChange={handleColorPickerVisibleChange}
            >
                <button
                    className="toolbar-item"
                    aria-label="Text Color"
                >
                    <FontColorsOutlined style={{ color: currentColor }} />
                </button>
            </Popover>
            <Divider />



            {isMobile ? (
                // On mobile devices, display a dropdown menu for alignment options
                <Dropdown overlay={alignMenu} trigger={['click']}>
                    <button className="toolbar-item" aria-label="Align">
                        <MenuOutlined />
                    </button>
                </Dropdown>
            ) : (
                // On larger screens, display separate buttons for alignment
                <>
                    <button
                        onClick={() => editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'left')}
                        className="toolbar-item"
                        aria-label="Align Left"
                    >
                        <AlignLeftOutlined />
                    </button>
                    <button
                        onClick={() => editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'center')}
                        className="toolbar-item"
                        aria-label="Align Center"
                    >
                        <AlignCenterOutlined />
                    </button>
                    <button
                        onClick={() => editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'right')}
                        className="toolbar-item"
                        aria-label="Align Right"
                    >
                        <AlignRightOutlined />
                    </button>
                    <button
                        onClick={() => editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'justify')}
                        className="toolbar-item"
                        aria-label="Justify"
                    >
                        <MenuOutlined />
                    </button>
                </>
            )}









        </div>
    );
}
