import { combineReducers } from 'redux';
import drawerReducer from './drawerReducer';
import authReducer from './authReducer';
import profileReducer from "./profileReducer";
import deckReducer from "./deckReducer";
import practiceReducer from "./practiceReducer";

const rootReducer = combineReducers({
    drawer: drawerReducer,
    auth: authReducer,
    profile: profileReducer,
    deck: deckReducer,
    practice: practiceReducer

});

export default rootReducer;