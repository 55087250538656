import React, { useState, useEffect } from 'react';
import { useSelector } from "react-redux";
import { message, Spin, Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import QuizCard from "../../components/QuizCard/QuizCard";
import practiceClient from "../../client/PracticeClient";

const QuizRunPage = () => {
    const navigate = useNavigate();

    // Получаем practiceId и practiceType из Redux
    const { practiceId, practiceType } = useSelector((state) => state.practice);

    // Состояния для квиза
    const [quiz, setQuiz] = useState(null);
    const [selectedOptions, setSelectedOptions] = useState(new Set());
    const [checked, setChecked] = useState(false);
    const [isMultipleCorrect, setIsMultipleCorrect] = useState(false);
    const [isLoading, setIsLoading] = useState(true); // Состояние загрузки
    const [error, setError] = useState(null);

    // Функция для получения квиза
    const fetchQuiz = async (quizRequest) => {
        setIsLoading(true);
        try {
            const data = await practiceClient.submitPracticeResult(quizRequest);
            setQuiz(data);
            setIsMultipleCorrect(data.answers.filter(answer => answer.isCorrect).length > 1);
            setChecked(false);
            setSelectedOptions(new Set());



            setIsLoading(false);
        } catch (err) {
            console.error("Error fetching quiz:", err);
            setError(err.message || 'Failed to fetch quiz.');
            setIsLoading(false);
        }
    };

    // Инициализация первого квиза при монтировании страницы
    useEffect(() => {
        if (practiceId && practiceType) {
            // Первый запрос не содержит quizId и correct
            fetchQuiz({
                practiceId,
                practiceType,
            });
        } else {
            setError('Practice ID or Practice Type is missing.');
            setIsLoading(false);
        }
    }, [practiceId, practiceType]);

    // Обработчик изменения опций
    const handleOptionChange = (event) => {
        if (isMultipleCorrect) {
            const newSelectedOptions = new Set(selectedOptions);
            if (newSelectedOptions.has(event.target.value)) {
                newSelectedOptions.delete(event.target.value);
            } else {
                newSelectedOptions.add(event.target.value);
            }
            setSelectedOptions(newSelectedOptions);
        } else {
            setSelectedOptions(new Set([event.target.value]));
        }
    };

    // Обработчик нажатия на кнопку "Check"
    const handleCheckClick = () => {
        setChecked(true);
    };

    // Обработчик нажатия на кнопку "Next"
    const handleNextClick = () => {
        if (!quiz) {
            message.error("No quiz available.");
            return;
        }

        const isCorrect = isMultipleCorrect
            ? Array.from(selectedOptions).every(option => isAnswerCorrect(option))
            : isAnswerCorrect([...selectedOptions][0] || '');

        // Отправляем результат квиза и получаем следующий квиз
        fetchQuiz({
            practiceId,
            practiceType,
            quizId: quiz.id,
            correct: isCorrect,
        });
    };

    // Функция проверки правильности ответа
    const isAnswerCorrect = (answerText) => {
        return quiz.answers.some(answer => answer.text === answerText && answer.isCorrect);
    };

    if (isLoading) {
        return (
            <div style={{ textAlign: 'center', marginTop: '50px' }}>
                <Spin tip="Loading quiz..." size="large" />
            </div>
        );
    }

    if (error) {
        return (
            <div style={{ textAlign: 'center', marginTop: '50px' }}>
                <p>Error: {error}</p>
                <Button type="primary" onClick={() => navigate('/')}>
                    Go Back
                </Button>
            </div>
        );
    }

    return quiz ? (
        <QuizCard
            quiz={quiz}
            isMultipleCorrect={isMultipleCorrect}
            selectedOptions={selectedOptions}
            handleOptionChange={handleOptionChange}
            checked={checked}
            handleCheckClick={handleCheckClick}
            handleNextClick={handleNextClick}
            isAnswerCorrect={isAnswerCorrect}
        />
    ) : (
        <div style={{ textAlign: 'center', marginTop: '50px' }}>
            <p>No quiz data available.</p>
        </div>
    );
};

export default QuizRunPage;
