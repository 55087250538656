import customFetch from "../utils/fetchUtils";

const presetClient = {
    getPresetsByDeckType: async (deckType, deckId, locale, translateLocale) => {
        return await customFetch(`/api/presets?deckType=${deckType}&deckId=${deckId}&locale=${locale}&translateLocale=${translateLocale}`, {
            method: 'GET',
        });
    },
    addPresetToClient: async (deckId, deckType, presetId) => {
        return await customFetch('/api/presets/add', {
            method: 'POST',
            body: JSON.stringify({ deckId, deckType, presetId }),
            headers: { 'Content-Type': 'application/json' },
        });
    },
    removePresetFromClient: async (deckId, deckType, presetId) => {
        return await customFetch('/api/presets/remove', {
            method: 'POST',
            body: JSON.stringify({ deckId, deckType, presetId }),
            headers: { 'Content-Type': 'application/json' },
        });
    },
};

export default presetClient;
