import customFetch from "../utils/fetchUtils";

const practiceClient = {
    initializePractice: async (practiceType, practiceRequest) => {
        return await customFetch(`/api/practice/init`, {
            method: 'POST',
            body: JSON.stringify({
                ...practiceRequest,
                practiceType,
            }),
            headers: {
                'Content-Type': 'application/json',
            },
        });
    },

    submitPracticeResult: async (practiceResult) => {
        return await customFetch(`/api/practice`, {
            method: 'POST',
            body: JSON.stringify(practiceResult),
            headers: {
                'Content-Type': 'application/json',
            },
        });
    },
};

export default practiceClient;
